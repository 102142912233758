$tight-grid-gutter-width: 8px;

.row-tight {
  @include make-row($gutter: $tight-grid-gutter-width);
}

@for $i from 1 through 12 {
  .col-tight-xs-#{$i} {
    @include make-xs-column($i, $gutter: $tight-grid-gutter-width);
  }
  .col-tight-xs-offset-#{$i} {
    @include make-xs-column-offset($i);
  }
  .col-tight-xs-push-#{$i} {
    @include make-xs-column-push($i);
  }
  .col-tight-xs-pull-#{$i} {
    @include make-xs-column-pull($i);
  }

  .col-tight-sm-#{$i} {
    @include make-sm-column($i, $gutter: $tight-grid-gutter-width);
  }
  .col-tight-sm-offset-#{$i} {
    @include make-sm-column-offset($i);
  }
  .col-tight-sm-push-#{$i} {
    @include make-sm-column-push($i);
  }
  .col-tight-sm-pull-#{$i} {
    @include make-sm-column-pull($i);
  }

  .col-tight-md-#{$i} {
    @include make-md-column($i, $gutter: $tight-grid-gutter-width);
  }
  .col-tight-md-offset-#{$i} {
    @include make-md-column-offset($i);
  }
  .col-tight-md-push-#{$i} {
    @include make-md-column-push($i);
  }
  .col-tight-md-pull-#{$i} {
    @include make-md-column-pull($i);
  }

  .col-tight-lg-#{$i} {
    @include make-lg-column($i, $gutter: $tight-grid-gutter-width);
  }
  .col-tight-lg-offset-#{$i} {
    @include make-lg-column-offset($i);
  }
  .col-tight-lg-push-#{$i} {
    @include make-lg-column-push($i);
  }
  .col-tight-lg-pull-#{$i} {
    @include make-lg-column-pull($i);
  }
}
