// A bordered container that can be a link and also allow
// links to appear visually "inside" it (e.g. an Edit link)
// while staying outside the link in the html

$_tile-padding: 15px;
$_tile-border-radius: $border-radius-base;
$_tile-hover-transition: $btn-transition;
$_tile-focus-outline-width: $btn-focus-outline-width;
$_tile-focus-outline-color: $btn-focus-outline-color;

.tile {
  position: relative;
}

.tile__content {
  display: block;
  padding: $_tile-padding;
  border: 1px solid $neutral-300;
  border-radius: $_tile-border-radius;
  color: $text-color;
  text-decoration: none;
  transition: background-color $_tile-hover-transition,
              box-shadow $_tile-hover-transition;
}

// only apply hover and focus effects if tile__content is a link
a.tile__content {
  &:hover,
  &:focus {
    text-decoration: none;
    color: $text-color;
    background: $neutral-100;
  }

  &:focus {
    box-shadow: 0 0 0 $_tile-focus-outline-width $_tile-focus-outline-color;
    outline: 0;
  }
}

.tile__control {
  position: absolute;
  top: 0;
  right: 0;
  padding: $_tile-padding;
}
