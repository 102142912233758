//
// Grid system
// This is mostly a direct copy of Bootstrap's grid
// to preserve backwards compatibility
// --------------------------------------------------

// Override Tailwind's max container size
.container {
  max-width: 1200px;
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
  @apply px-6 mx-auto;
  @include clearfix;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @apply -mx-6;
  @include clearfix;
}

.row-no-gutters {
  @apply mx-0;

  [class*="col-"] {
    @apply px-0;
  }
}


// Columns
//
// Common styles for small and large grid columns

@include tw-make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include tw-make-grid(xs);


// Small grid
//
// Columns, offsets, pushes, and pulls for the small device range, from phones
// to tablets.

@media (min-width: theme('screens.sm')) {
  @include tw-make-grid(sm);
}


// Medium grid
//
// Columns, offsets, pushes, and pulls for the desktop device range.

@media (min-width: theme('screens.md')) {
  @include tw-make-grid(md);
}


// Large grid
//
// Columns, offsets, pushes, and pulls for the large desktop device range.

@media (min-width: theme('screens.lg')) {
  @include tw-make-grid(lg);
}
