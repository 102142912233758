// show/hide content of a descendant (using opacity) based on the hover state of a parent element

.hover-show {
  .hover-show__target {
    opacity: 0;
    transition: opacity .2s;

    &:focus {
      // Makes sure it displays if navigating by keyboard
      opacity: 1;
    }
  }

  &:hover {
    .hover-show__target {
      opacity: 1;
    }
  }
}
