//
// Dropdown menus
// --------------------------------------------------


// Dropdown arrow/caret
.caret {
  margin-left: 2px;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  @apply inline-block w-0 h-0 align-middle;
}

.caret-up {
  margin-left: 2px;
  border-bottom: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  @apply inline-block w-0 h-0 align-middle;
}

// The dropdown wrapper (div)
.dropup,
.dropdown {
  @apply relative;
}

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus {
  @apply outline-none;
}

// The dropdown menu (ul)
.dropdown-menu {
  @apply py-2 px-0 mt-1 mb-0 mx-0 text-base rounded border border-gray-300 bg-white bg-clip-padding shadow-lg;
  // These styles are fairly specific so not using tailwind
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $z-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: 160px;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.white');
  }

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left: auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    @apply h-px my-2 mx-0 overflow-hidden bg-gray-200;
  }

  // Links within the dropdown menu
  > li > a {
    @apply block py-1.5 px-8 clear-both font-normal leading-8 text-gray-500 whitespace-nowrap;

    &:hover,
    &:focus {
      @apply text-brand-primary no-underline bg-gray-100;
    }
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    @apply text-brand-primary no-underline bg-gray-100 outline-none;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a {
  &,
  &:hover,
  &:focus {
    @apply text-gray-500;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    @apply no-underline cursor-not-allowed bg-transparent bg-none;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu {
    @apply block;
  }

  // Remove the outline when :focus is triggered
  > a {
    @apply outline-none;
  }
}

// Menu positioning
//
// Add extra class to `.dropdown-menu` to flip the alignment of the dropdown
// menu with the parent.
.dropdown-menu-right {
  @apply right-0 left-auto;
}
// With v3, we enabled auto-flipping if you have a dropdown within a right
// aligned nav component. To enable the undoing of that, we provide an override
// to restore the default dropdown menu alignment.
//
// This is only for left-aligning a dropdown menu within a `.navbar-right` or
// `.pull-right` nav component.
.dropdown-menu-left {
  @apply right-auto left-0;
}

// Dropdown section headers
.dropdown-header {
  @apply block py-1.5 px-8 text-sm text-gray-500 whitespace-nowrap;
}

// Backdrop to catch body clicks on mobile, etc.
.dropdown-backdrop {
  @apply fixed top-0 right-0 bottom-0 left-0;
  z-index: $z-dropdown-backdrop;
}

// Right aligned dropdowns
.pull-right > .dropdown-menu {
  @apply right-0 left-auto;
}

// Component alignment
//
// Reiterate per navbar.less and the modified component alignment there.

@media (min-width: theme('screens.sm')) {
  .navbar-right {
    .dropdown-menu {
      @apply right-0 left-auto;
    }
    // Necessary for overrides of the default right aligned menu.
    // Will remove come v4 in all likelihood.
    .dropdown-menu-left {
      @apply left-0 right-auto;
    }
  }
}
