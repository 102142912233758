//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.input-group {
  position: relative; // For dropdowns
  display: table;
  border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

  // Undo padding and float of grid classes
  &[class*='col-'] {
    @apply float-none px-0;
  }

  .form-control {
    // Ensure that the input is always above the *appended* addon button for
    // proper border colors.
    z-index: $z-input-group-control;
    @apply relative w-full mb-0;

    &:focus {
      z-index: $z-input-group-control-active;
    }
  }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .form-control {
  @apply table-cell;

  &:not(:first-child):not(:last-child) {
    @apply rounded-none;
  }
}
// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
  width: 1%;
  @apply whitespace-nowrap align-middle;
}

// Reset rounded corners
.input-group .form-control:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  @apply rounded-r-none;
}
.input-group .form-control:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  @apply rounded-l-none;
}

// Button input groups
// -------------------------
.input-group-btn {
  // Jankily prevent input button groups from wrapping with `white-space` and
  // `font-size` in combination with `inline-block` on buttons.
  font-size: 0;
  @apply relative whitespace-nowrap;

  // Negative margin for spacing, position for bringing hovered/focused/actived
  // element above the siblings.
  > .btn {
    @apply relative h-16;
    + .btn {
      @apply -ml-px;
    }
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active {
      z-index: $z-input-group-control;
    }
  }

  // Negative margin to only have a 1px border between the two
  &:first-child {
    > .btn,
    > .btn-group {
      @apply -mr-px;
    }
  }
  &:last-child {
    > .btn,
    > .btn-group {
      z-index: $z-input-group-control;
      @apply -ml-px;
    }
  }
}
