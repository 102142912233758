@import '@brainhubeu/react-carousel/lib/style.css';

.cx_weekly_day_picker {
  border-bottom: 1px solid g($color, 'lightGray');
  display: flex;
  height: 65px;
  width: 100%;
}

.cx_weekly_day_picker__date_picker {
  min-width: 232px;
}

.cx_weekly_day_picker__date_picker_label {
  color: g($color, 'blue');
  cursor: pointer;
  display: table;
  font-size: 20px;
  font-weight: 600;
}

.cx_weekly_day_picker__date_picker_icon {
  height: 20px;
  margin-bottom: 6px;
  margin-right: 6px;
  width: 20px;
}

.cx_weekly_day_picker__reel,
.cx_weekly_day_picker__weekdays {
  display: flex;
  align-items: center;
  overflow: auto;
  width: 100%;
}

.cx_weekly_day_picker__weekday {
  align-items: center;
  background: transparent;
  border: none;
  color: g($color, 'darkGray');
  display: inline-flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  height: 64px;
  margin: 0 24px;
  outline: none;
  padding: 0;
  width: 100%;

  &:focus {
    outline: 0;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.cx_weekly_day_picker__weekday--selected {
  border-bottom: 2px solid g($color, 'blue');
  color: g($color, 'blue');
}

.cx_weekly_day_picker__weekday_number {
  align-items: center;
  border-radius: 50%;
  display: inline-flex;
  height: 28px;
  justify-content: center;
  margin-top: 4px;
  width: 28px;

  .cx_weekly_day_picker__weekday:hover & {
    background-color: g($color, 'lightestGray');
  }

  .cx_weekly_day_picker__weekday--selected &,
  .cx_weekly_day_picker__weekday--selected:hover & {
    background-color: g($color, 'highlightBlue');
  }
}

.cx_weekly_day_picker__weekday--today {
  color: g($color, 'blue');
}

#calendar_redesign .react-datepicker__day--selected {
  background-color: g($color, 'darkBlue');
  border-radius: 50%;
  font-weight: 600;
}

#calendar_redesign .react-datepicker__day--highlighted:not(.react-datepicker__day--selected) {
  background-color: g($color, 'highlightBlue');
  border-radius: 50%;
  color: g($color, 'blue');
  font-weight: 600;
}
