//
// Close icons
// --------------------------------------------------

.close {
  font-size: 22.5px;
  @apply float-right font-bold leading-none text-black opacity-20;

  &:hover,
  &:focus {
    @apply text-black no-underline cursor-pointer opacity-50;
  }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
}
