.cx_nav {
  display: flex;
  position: relative;
  justify-content: center;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;

  padding: 1.5rem 0;
  background-color: g($color, 'white');
  box-shadow: g($shadow, 'elevation0');
  z-index: 1;
}

.cx_nav__list {
  @extend .cx_l--container;
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.cx_nav__item {
  &:not(:last-child) {
    margin-right: 2.75rem;
  }
}

.cx_nav__anchor {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.0125em;
  cursor: pointer;
  color: g($color, 'gray');

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: g($color, 'darkBlue');
  }

  .cx_nav__item--active & {
    font-weight: 800;
    color: g($color, 'darkBlue');
  }
}