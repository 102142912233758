//
// Breadcrumbs
// --------------------------------------------------

.breadcrumb {
  @apply py-3 px-6 mb-8 list-none bg-white rounded;

  > li {
    @apply inline-block;

    + li:before {
      content: '/\00a0'; // Unicode space added since inline-block means non-collapsing white-space
      @apply py-0 px-2 text-gray-300;
    }
  }

  > .active {
    @apply text-gray-500;
  }
}
