.cx_button {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;

  display: flex;
  justify-content: center;
  padding: 10px 12px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  border: 1px solid transparent;
  outline: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}

.cx_button--i {
  padding-left: 32px;
  background-position: 10px center;
  background-size: 16px 16px;
}

.cx_button--i-right {
  padding-right: 32px;
  background-position: calc(100% - 10px) center;
  background-size: 16px 16px;
}

.cx_button--i-right-background {
  background-position: calc(100% - 10px) center;
  background-size: 16px 16px;
}

.cx_button--i-left {
  padding-left: 32px;
  background-position: calc(5%) center;
  background-size: 16px 16px;
}

.cx_button--border-none {
  border: none;
}

.cx_button--cursor-default {
  cursor: default;
}

.cx_button--shadow {
  box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.24);
}

.cx_button--alpha,
a.cx_button--alpha {
  background-color: g($color, 'blue');
  border-color: g($color, 'blue');
  color: g($color, 'white');

  &:hover {
    background-color: g($color, 'mediumBlue');
    color: g($color, 'white');
    text-decoration: none;
  }

  &:active {
    background-color: g($color, 'mediumBlue');
    border-color: g($color, 'mediumBlue');
    box-shadow: 0 0 0 1px g($color, 'darkBlue');
    color: g($color, 'white');
    text-decoration: none;
  }

  &:disabled, &.cx_button--disabled {
    background-color: g($color, 'gray');
    border-color: g($color, 'gray');
    cursor: not-allowed;
  }
  &.cx_link_disabled {
    background-color: g($color, 'gray');
    border-color: g($color, 'gray');
    cursor: not-allowed;
  }
}

*.cx_button--beta,
a.cx_button--beta {
  background-color: g($color, 'white');
  border-color: g($color, 'blue');
  color: g($color, 'blue');

  &:hover {
    border-color: g($color, 'mediumBlue');
    color: g($color, 'mediumBlue');
    text-decoration: none;
  }

  &:active {
    border-color: g($color, 'mediumBlue');
    box-shadow: 0 0 0 1px g($color, 'darkBlue');
    color: g($color, 'mediumBlue');
    text-decoration: none;
  }

  &:disabled {
    cursor: default;
    border-color: g($color, 'gray');
    color: g($color, 'gray');
    cursor: not-allowed;
  }
}

*.cx_button--gamma,
a.cx_button--gamma {
  border-radius: 50%;

  &:hover {
    background-color: g($color, 'lightestGray');
  }

  &:active,
  &:focus {
    background-color: rgba(0, 114, 237, 0.1);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.cx_button--gray,
a.cx_button--gray {
  background-color: g($color, 'gray');
  border-color: g($color, 'gray');
  color: g($color, 'white');

  &:hover {
    background-color: g($color, 'lightGray');
    color: g($color, 'white');
    text-decoration: none;
  }

  &:active {
    background-color: g($color, 'lightGray');
    border-color: g($color, 'lightGray');
    box-shadow: 0 0 0 1px g($color, 'darkGray');
    color: g($color, 'white');
    text-decoration: none;
  }

  &:disabled {
    background-color: g($color, 'gray');
    border-color: g($color, 'gray');
    cursor: not-allowed;
  }
}

.cx_button--danger {
  &.cx_button--alpha {
    background-color: g($color, 'red');
  }

  &.cx_button--beta {
    color: g($color, 'red');
  }

  &.cx_button--beta-red {
    color: g($color, 'red');
    border: 1px solid g($color, 'red') !important;

    &:disabled {
      border: 1px solid g($color, 'gray') !important;
      color: g($color, 'gray');
      cursor: not-allowed;
    }
  }
}

.cx_button--tertiary,
a.cx_button--tertiary {
  background-color: g($color, 'highlightBlue');
  border-color: g($color, 'highlightBlue');
  color: g($color, 'blue');

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  &:active {
    box-shadow: 0 0 0 1px g($color, 'darkBlue');
    opacity: 0.8;
    text-decoration: none;
  }

  &:disabled {
    background-color: g($color, 'gray');
    border-color: g($color, 'gray');
    color: g($color, 'white');
    cursor: not-allowed;
  }
  &.cx_link_disabled {
    background-color: g($color, 'gray');
    border-color: g($color, 'gray');
    color: g($color, 'white');
    cursor: not-allowed;
  }
}

.cx_button--magnifying-glass {
  background-size: 50% !important;
}
