.cx_tooltip-container {
  background-color: g($color, 'white');
  border-radius: 1px;
  border: 1px solid g($color, 'blue');
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: 0.1rem 2rem;
  transition: opacity 0.3s;
  z-index: 9999;
}

.cx_tooltip-arrow {
  height: 1rem;
  position: absolute;
  width: 1rem;
}

.cx_tooltip-arrow::before {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  width: 0;
}

.cx_tooltip-arrow::after {
  border-style: solid;
  content: '';
  display: block;
  height: 0;
  margin: auto;
  position: absolute;
  width: 0;
}

.cx_tooltip-arrow[data-placement*='bottom'] {
  height: 1rem;
  left: 0;
  margin-top: -0.4rem;
  top: 0;
  width: 1rem;
}

.cx_tooltip-arrow[data-placement*='bottom']::before {
  border-color: transparent transparent g($color, 'blue') transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.cx_tooltip-arrow[data-placement*='bottom']::after {
  border-color: transparent transparent g($color, 'white') transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.cx_tooltip-arrow[data-placement*='top'] {
  bottom: 0;
  height: 1rem;
  left: 0;
  margin-bottom: -1rem;
  width: 1rem;
}

.cx_tooltip-arrow[data-placement*='top']::before {
  border-color: g($color, 'blue') transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.cx_tooltip-arrow[data-placement*='top']::after {
  border-color: g($color, 'white') transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.cx_tooltip-arrow[data-placement*='right'] {
  height: 1rem;
  left: 0;
  margin-left: -0.7rem;
  width: 1rem;
}

.cx_tooltip-arrow[data-placement*='right']::before {
  border-color: transparent g($color, 'blue') transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.cx_tooltip-arrow[data-placement*='right']::after {
  border-color: transparent g($color, 'white') transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.cx_tooltip-arrow[data-placement*='left'] {
  height: 1rem;
  margin-right: -0.7rem;
  right: 0;
  width: 1rem;
}

.cx_tooltip-arrow[data-placement*='left']::before {
  border-color: transparent transparent transparent g($color, 'blue');
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.cx_tooltip-arrow[data-placement*='left']::after {
  border-color: transparent transparent transparent g($color, 'white');
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}
