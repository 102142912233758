//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  @apply inline-block mb-0 px-6 py-3 rounded font-normal text-center
    align-middle cursor-pointer bg-none border border-transparent
    whitespace-nowrap text-base select-none transition duration-150;
  touch-action: manipulation;

  &:hover,
  &:focus,
  &.focus {
    @apply no-underline text-navy;

    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: theme('colors.brand-primary');
    }
  }

  &:focus,
  &.focus {
    @apply outline-none ring ring-blue;

    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      outline-color: theme('colors.blue.500');
    }
  }

  &:active,
  &.active {
    @apply bg-none outline-none;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    @apply cursor-not-allowed opacity-80;
  }
}

a.btn {
  &.disabled,
  fieldset[disabled] & {
    @apply pointer-events-none; // Future-proof disabling of clicks on `<a>` elements
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  @apply text-navy bg-white border-navy;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    color: theme('colors.navy.DEFAULT');
    border-color: theme('colors.navy.DEFAULT');
  }

  &:hover, &:focus, &:active {
    @apply text-navy-light bg-gray-50 border-navy-light;
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-500 border-gray-500;
  }
}

.btn-muted {
  @apply text-navy bg-gray-100 border-gray-100;

  &:hover, &:focus, &:active {
    @apply text-navy-light bg-gray-200 border-gray-200;
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-500 border-gray-100;
  }
}

.btn-primary {
  @apply text-white bg-navy border-navy;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.navy.DEFAULT');
    color: theme('colors.white');
  }

  &:hover, &:focus, &:active {
    @apply text-white bg-navy-light;

    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: theme('colors.white');
    }
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-100 bg-gray-500 border-gray-500;
  }
}

.btn-bright {
  @apply text-white bg-blue border-blue;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.blue.DEFAULT');
    color: theme('colors.white');
  }

  &:hover, &:focus, &:active {
    @apply text-white bg-blue-400;

    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: theme('colors.white');
    }
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-100 bg-gray-500 border-gray-500;
  }
}

.btn-success {
  @apply text-white bg-green;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.green.DEFAULT');
    color: theme('colors.white');
  }

  &:hover, &:focus, &:active {
    @apply text-white bg-green;
  }
}

.btn-info {
  @apply text-white bg-blue;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.blue.DEFAULT');
    color: theme('colors.white');
  }

  &:hover, &:focus, &:active {
    @apply text-white bg-blue;
  }
}

.btn-warning {
  @apply text-color bg-yellow;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.yellow.DEFAULT');
    color: theme('colors.black');
  }

  &:hover, &:focus, &:active {
    @apply text-color bg-yellow;
  }
}

.btn-danger {
  @apply text-white bg-red;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.red.DEFAULT');
    color: theme('colors.white');
  }

  &:hover, &:focus, &:active {
    @apply text-white bg-red;
  }
}

.btn-success, .btn-info, .btn-warning, .btn-danger {
  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-100 bg-gray-500;
  }

  &:hover, &:focus, &:active {
    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: theme('colors.white');
    }
  }
}

.btn-outline {
  @apply rounded-full border-gray-200;

  &:hover, &:focus, &:active {
    @apply bg-gray-200 border-gray-200;
  }

  &.disabled, &[disabled], fieldset[disabled] & {
    @apply text-gray-500 border-gray-100;
  }
}

// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  @apply font-normal text-link;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    @apply bg-transparent;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    @apply border-transparent;
  }
  &:hover,
  &:focus {
    @apply text-link-hover underline bg-transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      @apply text-gray-500 no-underline;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-full {
  @apply p-4 text-base rounded-md w-full;
}

.btn-lg {
  @apply px-8 py-4 text-lg rounded-md;
}

.btn-sm {
  @apply px-4 py-2 text-sm rounded-sm;
}

.btn-xs {
  @apply px-2 py-px text-sm rounded-sm;
}


// Block button
// --------------------------------------------------

.btn-block {
  @apply block w-full;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  @apply mt-1;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    @apply w-full;
  }
}
