// Base styles
@tailwind base;
@tailwind components;
@import 'elements';

// Vendor styles
@import 'bootstrap-variables';
@import '~vendorAssets/stylesheets/bootstrap-tw';
@import '~eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker';
@import '~vendorAssets/stylesheets/react-datepicker';
@import '~vendorAssets/stylesheets/fontello';
@import '~swagger-ui-react/swagger-ui.css';

// Variables and mixins
@import 'vars/z-index';
@import 'mixins/layout';
@import 'mixins/grid';
@import 'mixins/table';
@import 'mixins/forms';
@import 'mixins/panels';
@import 'mixins/reset_text';

// Layout and other stuff
@import 'icomoon-vehicles';
@import 'animate-spin';
@import 'grid-tight';
@import '~vendorAssets/stylesheets/bootstrap-tokenfield';
@import '~vendorAssets/stylesheets/timepicker';
@import '~vendorAssets/stylesheets/tagify';
@import 'autocomplete';
@import 'ratings_reviews';
@import 'vr';
@import 'sidenav';
@import 'stripe-buttons';
@import 'direct_uploads';
@import 'table-diagonal-header';

// ----------------------------------
// UI components replacing bootstrap
@import 'ui/print';
@import 'ui/glyphicons';

// @import 'ui/code';
@import 'ui/type';
@import 'ui/grid';
@import 'ui/tables';
@import 'ui/forms';
@import 'ui/buttons';

@import 'ui/animations';
@import 'ui/dropdowns';
@import 'ui/button-groups';
@import 'ui/input-groups';
@import 'ui/navs';
@import 'ui/navbar';
@import 'ui/breadcrumbs';
@import 'ui/pagination';
@import 'ui/labels';
@import 'ui/badges';
@import 'ui/thumbnails';
@import 'ui/alerts';
@import 'ui/media';
@import 'ui/list-group';
@import 'ui/panels';
@import 'ui/embed-responsive';
@import 'ui/wells';
@import 'ui/close';

@import 'ui/modals';
@import 'ui/tooltip';

@import 'ui/utilities';

@import 'bootstrap-overrides-tw';
// End former bootstrap stuff
// ----------------------------------

// Other Reusable Components
@import 'components/radio-card';
@import 'components/roles-checkbox';
@import 'components/vehicle-type';
@import 'components/spinner-overlay';
@import 'components/loading-spinner';
@import 'components/nav-circle';
@import 'components/datepicker';
@import 'components/pagination';
@import 'components/blocks';
@import 'components/tile';
@import 'components/segment';
@import 'components/checklist';
@import 'components/lists';
@import 'components/copy-text';
@import 'components/hover-show';
@import 'components/toggle';
@import 'components/checkbox';
@import 'components/radio-button';

// Utility classes
@import 'utils/accessibility';
@import 'utils/borders';
@import 'utils/letter-spacing';
@import 'utils/text-style';
@import 'utils/display';
@import 'utils/flexbox';
@import 'utils/font-weight';
@import 'utils/sizing';

// Styles for Dispatch Connect
@import 'connect/all';

// This 'application' import should always be last in this file, followed by tailwind utilities
@import 'application';
@tailwind utilities;
