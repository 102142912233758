// This file is to override or extend the base styling that bootstrap provides.
// Only put classes in here that are actually bootstrap classes.

.label-light {
  font-weight: normal;
  background: $neutral-300;
  color: $text-color;
}

.label-light-warning {
  font-weight: normal;
  color: $brand-warning;
  background: lighten($brand-warning, 50%);

  a {
    color: inherit;
  }
}

.label-copy {
  font-size: 1em;
}

h1,
.h1 {
  font-weight: 700;
}

a:focus {
  outline-offset: 0;
}
