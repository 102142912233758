.custom-checkbox {
  @apply font-normal;

  position: relative;
}

.custom-checkbox__input {
  opacity: 0;
  left: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 1;
  cursor: pointer;

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
  }
}

.custom-checkbox__label {
  &::before {
    @apply mr-3;

    content: '';
    display: inline-block;
    top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid #014e7d;
    position: relative;
  }
}

.custom-checkbox__input:checked + .custom-checkbox__label::after {
  content: '';
  position: absolute;
  transform: rotate(45deg);
  left: 5px;
  top: 4px;
  height: 9px;
  width: 6.5px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.custom-checkbox__input:disabled + .custom-checkbox__label::before,
.custom-checkbox__input[readonly] + .custom-checkbox__label::before {
  opacity: 0.5;
}

.custom-checkbox__input:checked + .custom-checkbox__label::before {
  @apply bg-brand-primary;
}

// :focus-visible targets keyboard interactions only, but only works in some browsers
.custom-checkbox__input:focus-visible + .custom-checkbox__label::before {
  @apply ring;
}
