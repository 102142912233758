//
// Tooltips
// --------------------------------------------------

$_ARROW-WIDTH: 5px;

// Base class
.tooltip {
  @apply block absolute text-sm opacity-0;
  z-index: $z-tooltip;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include tw-reset-text;

  &.in { @include opacity($tooltip-opacity); }
  &.top {
    padding: $_ARROW-WIDTH 0;
    margin-top: -3px;
  }
  &.right {
    padding: 0 $_ARROW-WIDTH;
    margin-left: 3px;
  }
  &.bottom {
    padding: $_ARROW-WIDTH 0;
    margin-top: 3px;
  }
  &.left {
    padding: 0 $_ARROW-WIDTH;
    margin-left: -3px;
  }

  // Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -$_ARROW-WIDTH;
    border-width: $_ARROW-WIDTH $_ARROW-WIDTH 0;
    border-top-color: theme('colors.black');
  }
  &.top-left .tooltip-arrow {
    right: $_ARROW-WIDTH;
    bottom: 0;
    margin-bottom: -$_ARROW-WIDTH;
    border-width: $_ARROW-WIDTH $_ARROW-WIDTH 0;
    border-top-color: theme('colors.black');
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    left: $_ARROW-WIDTH;
    margin-bottom: -$_ARROW-WIDTH;
    border-width: $_ARROW-WIDTH $_ARROW-WIDTH 0;
    border-top-color: theme('colors.black');
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -$_ARROW-WIDTH;
    border-width: $_ARROW-WIDTH $_ARROW-WIDTH $_ARROW-WIDTH 0;
    border-right-color: theme('colors.black');
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -$_ARROW-WIDTH;
    border-width: $_ARROW-WIDTH 0 $_ARROW-WIDTH $_ARROW-WIDTH;
    border-left-color: theme('colors.black');
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -$_ARROW-WIDTH;
    border-width: 0 $_ARROW-WIDTH $_ARROW-WIDTH;
    border-bottom-color: theme('colors.black');
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    right: $_ARROW-WIDTH;
    margin-top: -$_ARROW-WIDTH;
    border-width: 0 $_ARROW-WIDTH $_ARROW-WIDTH;
    border-bottom-color: theme('colors.black');
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    left: $_ARROW-WIDTH;
    margin-top: -$_ARROW-WIDTH;
    border-width: 0 $_ARROW-WIDTH $_ARROW-WIDTH;
    border-bottom-color: theme('colors.black');
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  @apply py-2 px-4 text-white bg-black rounded text-center;
}

.tooltip-narrow + .tooltip .tooltip-inner {
  max-width: 200px;
}

// Arrows
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
