.cx_styles {
  @extend .cx_l--container;
  padding-top: 72px;
}

.cx_styles__panel {
  background-color: g($color, 'white');
  border-top: 2px solid g($color, 'black');
  padding: 32px;
  box-shadow: g($shadow, elevation0);
  margin-bottom: 64px;
}

.cx_styles__title {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 32px;
}