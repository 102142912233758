.toggle {
  position: relative;
}

.toggle__input {
  margin: 0;
  padding: 0;
  border: none;
  max-width: 100%;
  position: absolute;
  width: 28px;
  height: 16px;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 1;

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
  }
}

.toggle__label {
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: block;
    position: relative;
    width: 28px;
    height: 16px;
    margin-right: 8px;
    border-radius: 16px;
    @apply bg-gray-500 border border-gray-50;
  }

  &::after {
    content: '';
    display: block;
    left: 0;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: left .15s;
    @apply bg-white border border-navy-light;
  }
}

.toggle__input:focus + .toggle__label::after,
.toggle__label:hover::after {
  @apply border-blue bg-gray-50;
}

// :focus-visible targets keyboard interactions only, but only works in some browsers
.toggle__input:focus-visible + .toggle__label::after {
  @apply ring;
}

.toggle__input:checked + .toggle__label::before {
  @apply bg-navy-light;
}

.toggle__input:checked + .toggle__label::after {
  left: 14px;
}

