.cx_preview {
  position: fixed;
  top: 175px;
  right: 0;
  width: 500px;
  height: calc(100% - 175px);
  z-index: 1000;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: g($color, 'white');
  box-shadow: g($shadow, elevation2);
}

.cx_preview__header {
  padding: 24px;
  background-color: g($color, 'blue');
  border-top-left-radius: 3px;
}

.cx_preview__close {
  position: absolute;
  top: 24px;
  right: 24px;
}

.cx_preview__sections {
  overflow: scroll;
}

.cx_preview__section {
  padding: 0 24px 0;
}

.cx_preview__heading {
  display: inline-block;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  .cx_preview__section--inactive & {
    padding-bottom: 0;
  }

  .cx_preview__section:first-of-type & {
    padding-top: 16px;
  }

  &:after {
    content: '';
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bWFzayBpZD0ibWFzazAiIG1hc2stdHlwZT0iYWxwaGEiIG1hc2tVbml0cz0idXNlclNwYWNlT25Vc2UiIHg9IjUiIHk9IjgiIHdpZHRoPSIxNCIgaGVpZ2h0PSI4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTExLjI5MjkgOC4yOTI4OUMxMS42ODM0IDcuOTAyMzcgMTIuMzE2NiA3LjkwMjM3IDEyLjcwNzEgOC4yOTI4OUwxOC43MDcxIDE0LjI5MjlDMTkuMDk3NiAxNC42ODM0IDE5LjA5NzYgMTUuMzE2NiAxOC43MDcxIDE1LjcwNzFDMTguMzE2NiAxNi4wOTc2IDE3LjY4MzQgMTYuMDk3NiAxNy4yOTI5IDE1LjcwNzFMMTIgMTAuNDE0Mkw2LjcwNzExIDE1LjcwNzFDNi4zMTY1OCAxNi4wOTc2IDUuNjgzNDIgMTYuMDk3NiA1LjI5Mjg5IDE1LjcwNzFDNC45MDIzNyAxNS4zMTY2IDQuOTAyMzcgMTQuNjgzNCA1LjI5Mjg5IDE0LjI5MjlMMTEuMjkyOSA4LjI5Mjg5WiIgZmlsbD0iIzAwM0E1RCIvPjwvbWFzaz48ZyBtYXNrPSJ1cmwoI21hc2swKSI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjMDAzQTVEIi8+PC9nPjwvc3ZnPg==');

    .cx_preview__section--active & {
      transform: rotate(180deg);
    }
  }
}

.cx_preview__content {
  .cx_preview__section--inactive & {
    max-height: 0;
    overflow: hidden;
  }
}
