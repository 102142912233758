.cx_waypoint {
  display: flex;
  position: relative;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px 6px 24px;
  border: 1px solid #e8e4e9;
  border-radius: 3px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.cx_waypoint__pickup-title {
  .cx_waypoint__pickup & {
    color: #2a282a;
  }
}

.cx_waypoint__pickup-indication {
  .cx_waypoint__pickup & {
    min-width: 2.5ch;
  }
}

.cx_waypoint__delivery-qty {
  .cx_waypoint__pickup & {
    margin-left: 20px;
    flex-basis: 100%;
  }
}

.cx_waypoint__delivery-qty-pickup {
  .cx_waypoint__pickup & {
    margin-top: 30px;
    margin-left: -434px;
    line-height: 0px;
  }
}

.cx_waypoint__pickup {
  border: none;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 12px 20px 6px;
  height: 50px;
  align-items: none;
  flex-wrap: wrap;

  &.cx_waypoint__dashboard-pickup {
    padding: 0px 12px 6px 6px;
    height: 50px;
  }
}

.cx_waypoint__figure {
  margin-left: -8px;
  margin-right: -26px;

  .cx_waypoint__vehicle-panel-dropoff & {
    position: relative;
    left: -10px;
    margin-left: -6px;
  }
}

.cx_waypoint__pickup-figure {
  margin-left: -4px;
  margin-right: 10px;
  z-index: 5;
  margin-top: -3px;
}

.cx_waypoint__attribute {
  .cx_waypoint__vehicle-panel-dropoff & {
    &:first-of-type {
      width: 16%;
      padding-bottom: 14px;
    }
    &:nth-of-type(2) {
      width: 45%;
      padding-top: 19px;
      margin-left: -120px;
    }
  }
}

.cx_waypoint--success {
  border: 1px solid g($color, 'green');
}

.cx_waypoint--danger {
  border: 1px solid g($color, 'red');
}

.cx_waypoint--highlight {
  @extend .cx_c--box-shadow-elevation2;
}

.cx_waypoint--grabbed {
  @extend .cx_c--box-shadow-elevation1;
  background-color: g($color, 'mediumGray') !important;
}

.cx_waypoint--border {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: absolute;
  height: 45px;
  width: 562px;
  left: 8px;
  z-index: 99;
  border-radius: 3px;
}

.cx_waypoint--border-pickup-unassigned {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 2px solid g($color, 'blue');
  position: absolute;
  height: 53px;
  width: 562px;
  top: -6px;
  left: 16px;
  border-radius: 3px;
}

.cx_waypoint--border-assigned {
  width: 577px !important;
}

.cx_waypoint--border-assigned-pickup {
  height: 52px !important;
}

.cx_waypoint--border-fade-out {
  opacity: 1;
  animation-name: fade-out;
  animation-duration: 2s;
  animation-delay: 4s;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.cx_waypoint--prefixed {
  margin-left: 8px;
  padding-left: 20px;
}

.cx_waypoint__prefix {
  position: absolute;
  top: 6px;
  left: -16px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(2ch + 12px);
  background-color: g($color, 'white');
  border-radius: 6px;
  border: 1px solid g($color, 'blue');
  font-size: 14px;
  line-height: 1;
  font-weight: 600;

  * {
    color: g($color, 'blue');
  }

  .cx_waypoint--success & {
    border-color: g($color, 'green');
    * {
      color: g($color, 'green');
    }
  }

  .cx_waypoint--danger & {
    border-color: g($color, 'red');
    * {
      color: g($color, 'red');
    }
  }

  .cx_waypoint--controlled:hover & {
    display: none;
  }
}

.cx_waypoint__controllers {
  padding: 0;
  margin: 0;

  margin-top: -3px;
  position: absolute;
  top: 0;
  left: -14px;
  display: none;

  .cx_waypoint:hover & {
    display: block;
  }
}

.cx_waypoint__controller {
  display: block;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  background-color: transparent;

  width: 24px;
  height: 22px;
  background-color: g($color, 'blue');
  border-radius: 3px;
  cursor: pointer;

  background-position: center;
  background-size: 83%;
  background-repeat: no-repeat;

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  &:hover {
    background-color: g($color, 'lightGray');
  }
}

.cx_waypoint__controller--disabled {
  background-color: g($color, 'lightGray');
  cursor: not-allowed;
}

.cx_waypoint__controller--up {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI1IiB5PSI4IiB3aWR0aD0iMTQiIGhlaWdodD0iOCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS4yOTI5IDguMjkyODlDMTEuNjgzNCA3LjkwMjM3IDEyLjMxNjYgNy45MDIzNyAxMi43MDcxIDguMjkyODlMMTguNzA3MSAxNC4yOTI5QzE5LjA5NzYgMTQuNjgzNCAxOS4wOTc2IDE1LjMxNjYgMTguNzA3MSAxNS43MDcxQzE4LjMxNjYgMTYuMDk3NiAxNy42ODM0IDE2LjA5NzYgMTcuMjkyOSAxNS43MDcxTDEyIDEwLjQxNDJMNi43MDcxMSAxNS43MDcxQzYuMzE2NTggMTYuMDk3NiA1LjY4MzQyIDE2LjA5NzYgNS4yOTI4OSAxNS43MDcxQzQuOTAyMzcgMTUuMzE2NiA0LjkwMjM3IDE0LjY4MzQgNS4yOTI4OSAxNC4yOTI5TDExLjI5MjkgOC4yOTI4OVoiIGZpbGw9IndoaXRlIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjbWFzazApIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ3aGl0ZSIvPjwvZz48L3N2Zz4=');
}

.cx_waypoint__controller--down {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSI1IiB5PSI4IiB3aWR0aD0iMTQiIGhlaWdodD0iOCI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01LjI5Mjg5IDguMjkyODlDNS42ODM0MiA3LjkwMjM3IDYuMzE2NTggNy45MDIzNyA2LjcwNzExIDguMjkyODlMMTIgMTMuNTg1OEwxNy4yOTI5IDguMjkyODlDMTcuNjgzNCA3LjkwMjM3IDE4LjMxNjYgNy45MDIzNyAxOC43MDcxIDguMjkyODlDMTkuMDk3NiA4LjY4MzQyIDE5LjA5NzYgOS4zMTY1OCAxOC43MDcxIDkuNzA3MTFMMTIuNzA3MSAxNS43MDcxQzEyLjMxNjYgMTYuMDk3NiAxMS42ODM0IDE2LjA5NzYgMTEuMjkyOSAxNS43MDcxTDUuMjkyODkgOS43MDcxMUM0LjkwMjM3IDkuMzE2NTggNC45MDIzNyA4LjY4MzQyIDUuMjkyODkgOC4yOTI4OVoiIGZpbGw9IndoaXRlIi8+PC9tYXNrPjxnIG1hc2s9InVybCgjbWFzazApIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ3aGl0ZSIvPjwvZz48L3N2Zz4=');
}

.cx_waypoint__attribute {
  display: flex;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }

  &:not(:last-child) {
    padding-right: 16px;
  }

  &:first-of-type {
    width: 17%;
    padding-bottom: 14px;
  }

  &:nth-of-type(2) {
    width: 42%;
    padding-top: 19px;
    margin-left: -135px;
  }

  &:nth-of-type(3) {
    width: 14%;
  }

  &:last-of-type {
    width: 24%;
  }
}

.cx_waypoint__spec {
  display: flex;

  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cx_flyout {
    margin-right: -12px;
  }
}

.cx_waypoint__indication {
  font-size: 12px;
  color: g($color, 'lightGray');
  margin-right: 8px;
  text-align: right;
}

.cx_waypoint__attribute--pickup {
  display: flex;
}

.cx_waypoint__drop-off-address {
  overflow: hidden;
  max-width: 30ch;
}

.cx_waypoint__pickup-address {
  max-width: 358px;
  width: 50ch;
}

.cx_waypoint__vehicle-panel-dropoff {
  padding-left: 34px;
  margin-left: 8px;
  z-index: auto;
  background-color: white;
}

.cx_waypoint__circle {
  width: 13px;
  height: 13px;
  z-index: 5;
  background-color: white;

  .cx_waypoint--grabbed & {
    background-color: g($color, 'mediumGray');
  }
}
