.cx_history-import--processing-truck {
  background-image: url('../images/delivery-and-truck.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 144px;
  height: 144px;
  margin-bottom: 60px;
}

.cx_history-import--title {
  font-size: 24px;
}
