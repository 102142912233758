@each $c, $h in $color {
  .cx_c--background-color-#{$c} {
    background-color: $h !important;
  }
}

@each $s, $h in $shadow {
  .cx_c--box-shadow-#{$s} {
    box-shadow: $h !important;
  }
}

@for $i from 0 through 12 {
  @if ($i % 1 == 0) {
    .cx_c--border-radius-#{$i} {
      border-radius: #{$i + 'px'} !important;
    }
  }
}

.cx_c--border-lightGray {
  border: 1px solid g($color, 'lightGray') !important;
}

.cx_c--border-red {
  border: 1px solid g($color, 'red');
}

.cx_c--border-blue {
  border: 1px solid g($color, 'blue');
}

.cx_c--dotted-joiner-orange {
  position: relative;

  &:not(:last-child):before {
    content: '';
    display: block;
    position: absolute;
    top: calc(100% - 4px);
    left: 4px;
    height: 100%;
    width: 1px;
    border: 1px dotted g($color, 'orange');
  }
}

@for $i from 1 through 4 {
  @each $c, $h in $color {
    .cx_c--border-#{$i}-#{$c} {
      border: #{$i + 'px'} solid $h;
    }

    .cx_c--border--bottom-#{$i}-#{$c} {
      border-bottom: #{$i + 'px'} solid $h !important;
    }
  }
}
