//
// Wells
// --------------------------------------------------

// Base class
.well {
  min-height: 20px;
  @apply p-8 mb-8 bg-gray-50 border border-gray-200 rounded;
}

// Colors
.well-light {
  @apply bg-white;
}

// Sizes
.well-lg {
  @apply p-10 rounded-lg;
}
.well-sm {
  @apply p-4;
}
