.sidenav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  // We assume this is in a container with 15px padding on the left.
  // This is to push it to the side of the window.
  margin-left: -15px;
}

.sidenav .sidenav-item {
  position: relative;
  display: block;
}

.sidenav .sidenav-item a {
  display: block;
  padding: 10px 20px;
}

.sidenav .sidenav-item:hover,
.sidenav .sidenav-item:focus {
  background-color: $gray-lighter;
}

.sidenav .sidenav-item a:hover,
.sidenav .sidenav-item a:focus {
  text-decoration: none;
}

.sidenav .sidenav-item.active {
  background-color: $gray-lighter;
  box-shadow: inset 4px 0 0 $gray-dark;
}

.sidenav .sidenav-item a {
  margin-left: 4px;
  // padding: 10px 16px;
}

.sidenav .sidenav-item.active .sidenav-sub {
  background-color: $gray-lightest2;
}

.sidenav-sub {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-left: 4px;
}

.sidenav-sub .sidenav-sub-item {
  position: relative;
  display: block;
}

.sidenav-sub .sidenav-sub-item a {
  display: block;
  padding: 10px 30px;
}

.sidenav-sub .sidenav-sub-item a:hover,
.sidenav-sub .sidenav-sub-item a:focus {
  text-decoration: none;
  background-color: $gray-lighter;
}

.sidenav .sidenav-sub-item.active {
  background-color: $gray-lighter;
}
