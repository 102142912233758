.cx_table {
  width: 100%;
  color: g($color, 'darkGray');
  border-collapse: separate;
}

.cx_table--border-collapse {
  border-collapse: collapse;
}

.cx_table__caption {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  letter-spacing: 0.002em;
  color: g($color, 'darkGray');
  padding: 0;

  .cx_table--alpha & {
    margin-bottom: 0;
  }
}

.cx_table__heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  text-transform: uppercase;

  .cx_table--alpha & {
    color: g($color, 'gray');
    padding: 16px 0;
  }

  .cx_table--beta & {
    color: g($color, 'darkBlue');
    border-bottom: 1px solid g($color, 'blue');
    padding-bottom: 8px;
  }

  &:first-child {
    .cx_table--beta & {
      padding-left: 16px;
    }
  }

  &:last-child {
    .cx_table--beta & {
      padding-right: 16px;
    }
  }
}

.cx_table__row {
  min-height: 48px;
}

.cx_table__row--selectable {
  cursor: pointer;
}

.cx_table__row--flyout-highlight {
  background-color: g($color, 'highlightBlue');
}

.cx_table__row--saved-location {
  background-color: #F7F7F7;
}

.cx_table__row-first-border {
  border-bottom: 1px solid g($color, 'lightGray');
}

.cx_table__column {
  min-height: 60px;
  padding: 7px 0;

  &:first-child {
    padding-left: 16px;
  }

  &:last-child {
    padding-right: 16px;
  }

  &:not(:last-child) {
    padding-right: 7px;
  }

  .cx_table--beta .cx_table__row & {
    padding: {
      top: 12px;
      bottom: 12px;
    }
  }

  .cx_table__row:not(:last-child) & {
    border-bottom: 1px solid g($color, 'lightGray');
  }

  .cx_table__row--border-none:not(:last-child) & {
    border-bottom: none;
  }
}

.cx_table__column--vertical-align-top {
  vertical-align: top !important;
}

.cx_table__span {
  display: flex;
  width: 100%;
  line-height: 1.5;
}

.cx_table__span--nwr {
  white-space: nowrap;
}

.cx_table__cell--selected {
  background-color: rgba(185, 37, 0, 0.05);
}

.cx_table__cell--highlighted {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}

.cx_table__cell--tooltip {
  height: 15px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -27px;
  left: 50%;
}

.cx_table__cell--tooltip-content {
  position: relative;
  left: -50%;
  background-color: g($color, 'white');
  font-size: 10px;
  font-weight: 600;
  color: g($color, 'red');
  padding: 4px 7px;
}

.cx_table__cell--tooltip-content::before {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -13px;
  border: 6px solid transparent;
  border-top-color: g($color, 'lightGray');
}

.cx_table__cell--tooltip-content::after {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -13px;
  border: 7px solid transparent;
  border-top-color: g($color, 'white');
}

.cx_table__buttons-container {
  left: 0
}
