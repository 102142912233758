//
// Thumbnails
// --------------------------------------------------

// Mixin and adjust the regular image class
.thumbnail {
  padding: 4px;
  transition: border 0.2s ease-in-out;
  @apply block mb-8 bg-white border border-gray-300 rounded;

  > img,
  a > img {
    @apply block max-w-full h-auto mx-auto;
  }

  // [converter] extracted a&:hover, a&:focus, a&.active to a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active

  // Image captions
  .caption {
    @apply p-3.5 text-color;
  }
}

// Add a hover state for linked versions only
a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
  @apply border-link;
}
