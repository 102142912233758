//
// Typography
// --------------------------------------------------


// Headings
// -------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  @apply font-sans font-semibold leading-none text-brand-primary;

  small,
  .small {
    @apply font-normal leading-none text-gray-500;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  @apply mt-8 mb-4;

  small,
  .small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  @apply my-4;

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1 { @apply text-4xl; }
h2, .h2 { @apply text-3xl; }
h3, .h3 { @apply text-2xl; }
h4, .h4 { @apply text-lg; }
h5, .h5 { @apply text-base; }
h6, .h6 { @apply text-sm; }

.h-small {
  @apply uppercase tracking-wider text-xs text-gray-500;
}

// Body text
// -------------------------

p {
  @apply mt-0 mb-4;
}

.lead {
  @apply mb-8 text-lg font-light leading-snug;

  @media (min-width: $screen-sm-min) {
    @apply text-xl;
  }
}


// Emphasis & misc
// -------------------------

// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
  @apply text-sm;
}

// Contextual colors
.text-muted {
  @apply text-gray-500;
}

.text-danger {
  @apply text-red;
}

// Page header
// -------------------------

.page-header {
  @apply pb-4 mt-16 mb-8 border-b border-gray-300;
}


// Description Lists
dl { @apply mt-0 mb-8; }
dt { @apply font-bold; }
dd { @apply ml-0; }

// Misc
// -------------------------

// Abbreviations and acronyms
abbr[title],
abbr[data-original-title] {
  @apply cursor-help;
}
