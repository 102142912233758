//
// Utility classes
// NOTE: these are specialized cases for backwards compatibility with Bootstrap.
// Use Tailwind classes for other utilities.
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  @include clearfix;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}

// Responsive images (ensure images don't scale beyond their parents)
// -------------------------
.img-responsive {
  display: block;
  max-width: 100%; // Part 1: Set a maximum relative to the parent
  height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

// Only display content to screen readers
//
// See: https://a11yproject.com/posts/how-to-hide-content
// -------------------------
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate
// -------------------------
.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}
