.cx_modal {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.cx_optimization-ind-vehicle-modal {
  position: absolute;
}

.cx_optimization-ind-vehicle-modal-box {
  width: 465px !important;
  height: 146px;
}

.cx_optimization-ind-vehicle-modal-overlay {
  background-color: rgba(0, 0, 0, 0.23) !important;
}

.cx_plan-it-error-i {
  position: relative;
  bottom: 3px;
}
.cx_plan-it-error-box {
  height: 182px;
  width: 647px !important;
}

.cx_plan-it-error-modal-header {
  font-size: 24px;
  font-weight: 600;
  margin-left: 11px;
}

.cx_plan-it-error-modal-x {
  position: absolute;
  right: 10px;
  top: 16px;
  height: 24px;
  width: 24px;
}

.cx_plan-it-error-modal-text {
  height: 60px;
  width: 459px;
  position: absolute;
  left: 105px;
  top: 46px;
  line-height: 21px;
  color: #2a2b2a;
}

.cx_modal-exit-x {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  border: none;
  background-color: transparent;
}

.cx_modal_top {
  height: 30%;
}

.cx_modal_justify_start {
  justify-content: flex-start !important;
}

.cx_modal__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(6, 25, 56, 0.5);
}

.cx_modal__overlay_gray {
  background-color: rgba(42, 40, 42, 0.24);
}

.cx_modal__box {
  position: relative;
  max-width: 572px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 24px;
  background-color: g($color, 'white');
  border: 1px solid g($color, 'white');
  box-shadow: g($shadow, elevation1);
  border-radius: 3px;
}

.cx_modal__box_long {
  padding: 15px;

  @media (min-width: 768px) {
    & {
      width: 750px;
    }
  }

  @media (min-width: 992px) {
    & {
      width: 970px;
    }
  }

  @media (min-width: 1200px) {
    & {
      width: 1170px;
    }
  }
}

.cx_modal__box_margin_top--8 {
  margin-top: 8%;
}

.cx_modal__box_margin_top--15 {
  margin-top: 15%;
}

.cx_modal__flex_content_three {
  width: 80%;

  @media (min-width: 768px) {
    & {
      margin-left: 0;
    }
  }

  @media (min-width: 992px) {
    & {
      margin-left: -30px;
    }
  }

  @media (min-width: 1200px) {
    & {
      margin-left: -60px;
    }
  }
}

.cx_modal__flex_content_two {
  @media (min-width: 768px) {
    & {
      margin-left: 0;
      width: 92%;
    }
  }

  @media (min-width: 992px) {
    & {
      margin-left: -30px;
      width: 95%;
    }
  }

  @media (min-width: 1200px) {
    & {
      margin-left: -60px;
      width: 95%;
    }
  }
}

.cx_response_modal--box {
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  width: auto !important;
}

.cx_issues_modal--box {
  width: 90% !important;
  max-width: 800px;
}

.cx_response_modal--container {
  width: 500px;
  min-height: 240px;
}

.cx_response_modal--header-success,
.cx_response_modal--header-fail {
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cx_response_modal--header-success::before,
.cx_response_modal--header-fail::before {
  content: '';
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -30px;
  border: 15px solid transparent;
}

.cx_response_modal--header-success::before {
  border-top-color: #3B855D;
}

.cx_response_modal--header-fail::before {
  border-top-color: #B92500;
}

.cx_response_modal--header-success {
  background-color: #3B855D;
}

.cx_response_modal--header-fail {
  background-color: #B92500;
}
