//
// Button groups
// --------------------------------------------------

// Make the div behave like a button
.btn-group {
  @apply relative inline-block align-middle;

  > .btn {
    @apply relative float-left;
    // Bring the "active" button to the front
    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: $z-btn-group-active;
    }
  }
}

// Prevent double borders when buttons are next to each other
.btn-group {
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    @apply -ml-px;
  }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
  @apply -ml-2; // Offset the first child's margin
  @include clearfix;

  .btn,
  .btn-group,
  .input-group {
    @apply float-left;
  }
  > .btn,
  > .btn-group,
  > .input-group {
    @apply -ml-2;
  }
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  @apply rounded-none;
}

// Set corners individual because sometimes a single button can be in a .btn-group and we need :first-child and :last-child to both match
.btn-group > .btn:first-child {
  @apply ml-0;
  &:not(:last-child):not(.dropdown-toggle) {
    @apply rounded-r-none;
  }
}
// Need .dropdown-toggle since :last-child doesn't apply, given that a .dropdown-menu is used immediately after it
.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  @apply rounded-l-none;
}

// Custom edits for including btn-groups within btn-groups (useful for including dropdown buttons within a btn-group)
.btn-group > .btn-group {
  @apply float-left;
}
.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  @apply rounded-none;
}
.btn-group > .btn-group:first-child:not(:last-child) {
  > .btn:last-child,
  > .dropdown-toggle {
    @apply rounded-r-none;
  }
}
.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  @apply rounded-l-none;
}

// On active and open, don't show outline
.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  @apply outline-none;
}

// Reposition the caret
.btn .caret {
  @apply ml-0;
}
// Carets in other button sizes
.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

[data-toggle='buttons'] {
  > .btn,
  > .btn-group > .btn {
    input[type='radio'],
    input[type='checkbox'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
