.cx_pagination-container {
  color: g($color, 'blue');
  display: flex;
  font-size: 14px;
  font-weight: 600;
  list-style-type: none;
  padding: 0;

  .cx_pagination-item {
    border-radius: 50%;
    height: 24px;
    margin: 0 2px;
    text-align: center;
    min-width: 24px;

    > button {
      background: transparent;
      border: none;
      height: 100%;
      outline: none;
      padding: 2px;
      width: 100%;

      &:disabled {
        cursor: not-allowed;
      }
    }

    &.cx_pagination-item--dots {
      margin: 0;
      min-width: 10px;
      padding-top: 2px;
    }

    &.selected {
      background-color: g($color, 'blue');
      color: g($color, 'white');
    }

    &:not(.selected).cx_pagination-item--dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:not(.selected):hover {
      background-color: g($color, 'highlightBlue');
    }

    &:not(.selected).disabled:hover {
      background-color: g($color, 'lightestGray');
    }
  }

  .cx_pagination-arrow {
    > button {
      background: transparent;
      border: none;
      height: 100%;
      outline: none;
      padding: 0;
      width: 100%;

      > i {
        border-radius: 50%;
        margin: 0;
      }

      &:hover:enabled > i {
        background-color: g($color, 'highlightBlue');
      }

      &:hover:disabled > i {
        background-color: g($color, 'lightestGray');
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
