//
// Panels
// --------------------------------------------------

// Base class
.panel {
  @apply mb-8 bg-white border border-gray-200 rounded;
}

// Panel contents
.panel-body {
  @include clearfix;
  @apply p-6;
}

// Optional heading
.panel-heading {
  @apply border-b border-gray-200 px-6 py-4 rounded-t-sm;

  > .dropdown .dropdown-toggle {
    color: inherit;
  }
}

// List groups in panels
//
// By default, space out list group content from panel headings to account for
// any kind of custom content between the two.

.panel {
  > .list-group,
  > .panel-collapse > .list-group {
    @apply mb-0;

    .list-group-item {
      border-width: 1px 0;
      border-radius: 0;
    }

    // Add border top radius for first one
    &:first-child {
      .list-group-item:first-child {
        @apply border-t-0 rounded-t-sm;
      }
    }

    // Add border bottom radius for last one
    &:last-child {
      .list-group-item:last-child {
        @apply border-b-0 rounded-b-sm;
      }
    }
  }
  > .panel-heading + .panel-collapse > .list-group {
    .list-group-item:first-child {
      @apply rounded-t-none;
    }
  }
}
// Collapse space between when there's no additional content.
.panel-heading + .list-group {
  .list-group-item:first-child {
    @apply border-t-0;
  }
}
.list-group + .panel-footer {
  @apply border-t-0;
}

// Collapsible panels (aka, accordion)
//
// Wrap a series of panels in `.panel-group` to turn them into an accordion with
// the help of our collapse JavaScript plugin.

.panel-group {
  @apply mb-8;

  // Tighten up margin so it's only between panels
  .panel {
    @apply mb-0 rounded;

    + .panel {
      @apply mt-2;
    }
  }

  .panel-heading {
    @apply border-b-0;

    + .panel-collapse > .panel-body,
    + .panel-collapse > .list-group {
      @apply border-t border-gray-200;
    }
  }

  .panel-footer {
    @apply border-t-0;

    + .panel-collapse .panel-body {
      @apply border-b border-gray-200;
    }
  }
}

// Contextual variations
.panel-primary {
  @include tw-panel-variant(
    theme('colors.brand-primary'),
    theme('colors.white'),
    theme('colors.brand-primary')
  );
}

.panel-success {
  @include tw-panel-variant(
    theme('colors.green.300'),
    theme('colors.green.800'),
    theme('colors.green.200')
  );
}
.panel-info {
  @include tw-panel-variant(
    theme('colors.blue.200'),
    theme('colors.blue.700'),
    theme('colors.blue.150')
  );
}
.panel-warning {
  @include tw-panel-variant(
    theme('colors.yellow.300'),
    theme('colors.yellow.900'),
    theme('colors.yellow.200')
  );
}
.panel-danger {
  @include tw-panel-variant(
    theme('colors.red.100'),
    theme('colors.red.600'),
    theme('colors.red.50')
  );
}
