.autocomplete {
  position: relative;
}

.autocomplete-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  float: left;
  width: 100%;
  padding: 5px 0;
  margin: 2px 0 0; // override default ul
  list-style: none;
  font-size: $font-size-base;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  background-color: $dropdown-bg;
  border: 1px solid $dropdown-fallback-border; // IE8 fallback
  border: 1px solid $dropdown-border;
  border-radius: $border-radius-base;
  @include box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
  background-clip: padding-box;

  .divider {
    @include nav-divider($dropdown-divider-bg);
  }

  // Selectable items within the menu
  .autocomplete-menu-item {
    display: block;
    padding: 6px 15px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    overflow: hidden;
    white-space: nowrap;
  }

  &.org-referral-autocomplete-menu {
    max-height: 200px;
    overflow: scroll;
  }
}

// Hover/Focus state
.autocomplete-menu .autocomplete-menu-selectable {
  &:hover,
  &:focus {
    text-decoration: none;
    // color: $dropdown-link-hover-color;
    background-color: $dropdown-link-hover-bg;
  }
}

.autocomplete-menu .active {
  &,
  &:hover,
  &:focus {
    // color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
}

.autocomplete-menu .disabled {
  &,
  &:hover,
  &:focus {
    // color: $dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    @include reset-filter;
    cursor: $cursor-disabled;
  }
}

.autocomplete-googleimage {
  max-width: 110px;
}

.autocomplete-menu-item-icon {
  display: inline-block;
  vertical-align: top;
  min-width: 28px;
  padding-right: 8px;
  text-align: center;
  color: $gray-lightest;
}

.autocomplete-menu-item-contents {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
