@import 'utils/accordian';
@import 'utils/var';
@import 'utils/borders';
@import 'utils/block';
@import 'utils/icon';
@import 'utils/layout';
@import 'utils/chrome';
@import 'utils/type';

@import 'block/badges';
@import 'block/button';
@import 'block/dashboard';
@import 'block/dropdown';
@import 'block/field';
@import 'block/fieldset';
@import 'block/floating-menu.scss';
@import 'block/flyout';
@import 'block/modal';
@import 'block/nav';
@import 'block/preview';
@import 'block/table';
@import 'block/tabs';
@import 'block/thumbnails.scss';
@import 'block/vehicle-panel';
@import 'block/waypoint';
@import 'block/toast';
@import 'block/weekly-day-picker';
@import 'block/map-pin';
@import 'block/circle-steps';
@import 'block/pagination';
@import 'block/settings';
@import 'block/upload.scss';
@import 'block/processing-throbber';
@import 'block/tooltip';
@import 'block/reporting';

@import 'view/styleguide';
@import 'big-spinner';
@import 'delivery_overview';
@import 'omnibox';
@import 'waypoint_grid_item';

.cx_ {
  background-color: g($color, 'offwhite');
  padding-bottom: 100px;
}
