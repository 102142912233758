.cx_circle-steps {
  height: 82px;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0;
  text-align: center;

  @media screen and (max-width: 1199px) {
    max-width: 940px;
  }

  @media screen and (max-width: 991px) {
    max-width: 720px;
  }

  @media screen and (max-width: 599px) {
    max-width: 470px;
  }
}

.cx_circle-step {
  display: inline-block;
  margin-right: 32%;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.cx_circle-step__button {
  background: g($color, 'blue');
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjAuNzA3MSA1LjI5Mjg5QzIxLjA5NzYgNS42ODM0MiAyMS4wOTc2IDYuMzE2NTggMjAuNzA3MSA2LjcwNzExTDkuNzA3MTEgMTcuNzA3MUM5LjMxNjU4IDE4LjA5NzYgOC42ODM0MiAxOC4wOTc2IDguMjkyODkgMTcuNzA3MUwzLjI5Mjg5IDEyLjcwNzFDMi45MDIzNyAxMi4zMTY2IDIuOTAyMzcgMTEuNjgzNCAzLjI5Mjg5IDExLjI5MjlDMy42ODM0MiAxMC45MDI0IDQuMzE2NTggMTAuOTAyNCA0LjcwNzExIDExLjI5MjlMOSAxNS41ODU4TDE5LjI5MjkgNS4yOTI4OUMxOS42ODM0IDQuOTAyMzcgMjAuMzE2NiA0LjkwMjM3IDIwLjcwNzEgNS4yOTI4OVoiIGZpbGw9IiNGRkZGRkYiLz4KPC9zdmc+Cg==');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px 30px;
  border: 1px solid g($color, 'blue');
  border-radius: 100%;
  box-shadow: 0px 3px 12px rgba(23, 43, 77, 0.04), 3px 5px 12px rgba(23, 43, 77, 0.08);
  box-sizing: border-box;
  color: g($color, 'white');
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 46px;
  width: 48px;
}

.cx_circle-step__label {
  font-size: 16px;
  left: -76px;
  margin-top: 12px;
  position: absolute;
  width: 200px;
}

.cx_circle-step::before {
  background: g($color, 'blue');
  content: '';
  height: 4px;
  left: -365px;
  position: absolute;
  top: 22px;
  width: 365px;
  z-index: -1;

  @media screen and (max-width: 1199px) {
    left: -301px;
    width: 301px;
  }

  @media screen and (max-width: 991px) {
    left: -231px;
    width: 231px;
  }

  @media screen and (max-width: 599px) {
    left: -183px;
    width: 183px;
  }
}


.cx_circle-step:first-child::before {
  display: none;
}

.cx_circle-step:last-child {
  margin-right: 0;
}

.cx_circle-step--active .cx_circle-step__button {
  background: g($color, 'white');
  border: 4px solid g($color, 'blue');
  color: g($color, 'black');
  line-height: 40px;
}

.cx_circle-step:first-child.cx_circle-step--active .cx_circle-step__button {
  background: g($color, 'blue');
  color: g($color, 'white');
}

.cx_circle-step--active .cx_circle-step__label {
  font-weight: 700;
}

.cx_circle-step--active ~ .cx_circle-step .cx_circle-step__button {
  background: g($color, 'white');
  color: g($color, 'black');
}

.cx_circle-step--active ~ .cx_circle-step::before {
  height: 1px;
  top: 22.5px;
}
