.radio-card {
  display: block;
  margin: $radio-card-margin;
  border: $radio-card-border-width $input-border solid;
  border-radius: $border-radius-base;
  cursor: pointer;
  transition: box-shadow $btn-transition;

  .has-error & {
    border: $radio-card-border-width solid $brand-danger;
  }
}

input:disabled + .radio-card,
.radio-card.disabled {
  cursor: not-allowed;
  background-color: $neutral-200;
  border-color: $neutral-300;
}

input:focus + .radio-card,
.radio-card.focused {
  border: $radio-card-border-width solid $neutral-700;
  @include box-shadow(0 0 0 $btn-focus-outline-width theme('colors.blue.300'));
}

input:checked + .radio-card,
.radio-card.selected {
  border: $radio-card-border-width solid $brand-primary;
  @include box-shadow(0 0 0 $btn-focus-outline-width $brand-primary);
}

input:checked:focus + .radio-card,
.radio-card.focused.selected {
  border: $radio-card-border-width solid $brand-primary;
  @include box-shadow(
    0 0 0 $btn-focus-outline-width $brand-primary,
    0 0 0 ($btn-focus-outline-width * 2) theme('colors.blue.300')
  );
}

.radio-card--switch {
  border-color: $brand-primary;
  border-radius: 0;
  margin: 0;
  font-weight: 600;

  &:first-of-type {
    border-top-left-radius: $border-radius-base;
    border-bottom-left-radius: $border-radius-base;
  }

  &:last-of-type {
    border-top-right-radius: $border-radius-base;
    border-bottom-right-radius: $border-radius-base;
  }

  input:checked + & {
    @include box-shadow(none);
    color: $white;
    background-color: $brand-primary;
  }
}

.radio-card__container {
  margin: 0 - $radio-card-margin;
}

.radio-card__container--switch {
  margin: 0;
}

// HTML5 Boilerplate accessible hidden styles
.radio-card [type="radio"] {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px; margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

