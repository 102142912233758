.cx_reporting {
  @media print {
    // Adjust size
    width: 1076px;

    // Remove extra spacing
    > .cx_l--container {
      padding: 0 !important;
    }

    // Avoid content breaking on page change
    div {
      box-shadow: none !important;
      page-break-inside: avoid;
    }
  }
}
