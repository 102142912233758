@mixin tw-form-control-validation($static-color, $focus-color, $ring-color) {
  .error-message,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label  {
    color: $static-color;
  }
  // Set the border and box shadow on specific inputs to match
  .form-control {
    border-color: $static-color;

    &:focus {
      border-color: $focus-color;
      // This box-shadow should be equivalent to Tailwind's 'ring' class, with custom coloration
      box-shadow: 0 0 0 3px $ring-color;
    }
  }

  .form-control-feedback {
    border-color: $static-color;
  }
}
