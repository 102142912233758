// copy-text is a label-like interface for copying a small amount of text

.copy-text {
  display: inline-flex;
  align-items: center;
  border-radius: $border-radius-base;
  line-height: 1;
  background: $neutral-300;
  max-width: 100%;

  // prevents a line break happening in bootstrap's tooltip
  .tooltip-inner {
    white-space: nowrap;
  }
}

.copy-text__text {
  vertical-align: middle;
  padding: 0 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-text__button {
  appearance: none;
  border: 0;
  padding: 4px 5px;
  border-left: 1px solid $neutral-700;
  border-radius: 0 $border-radius-base $border-radius-base 0;
  position: relative;
  background: transparent;
  vertical-align: middle;

  &:hover,
  &:focus {
    background-color: $neutral-700;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    border-radius: 0 $border-radius-base $border-radius-base 0;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.success::after {
    background-color: desaturate(lighten($brand-green, 50%), 40%);
    background-image: url('~assets/images/greenCheck.svg');
  }

  &.failure::after {
    background-color: desaturate(lighten($brand-red, 50%), 40%);
    background-image: url('~assets/images/redX.svg');
  }
}
