//
// Forms
// --------------------------------------------------


// Normalize non-controls
//
// Restyle and baseline non-control form elements.

fieldset {
  // Chrome and Firefox set a `min-width: min-content;` on fieldsets,
  // so we reset that to ensure it behaves more like a standard block element.
  // See https://github.com/twbs/bootstrap/issues/12359.
  @apply min-w-0 border-0;
}

legend {
  @apply block w-full p-0 mb-6 text-xl text-blue-900 border-0 border-b border-gray-200;
  line-height: inherit;
}

label {
  @apply inline-block max-w-full mb-2 font-semibold text-blue-900;
}

label,
legend {
  abbr[title] {
    @apply text-red no-underline text-lg;
    cursor: inherit;
    // These things are here so that the larger font size of the asterisk doesn't push down content
    display: inline-block;
    margin-top: -3px;
    height: 1em;
  }
}


// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.

input[type="search"] {
  // Override content-box in Normalize (* isn't specific enough)
  // apperance-none is to normalize rounded corners in iOS
  @apply box-border appearance-none;

  // Hides the default 'x' icon in search fields
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { display: none; }
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  @apply m-0 mb-1.5 mt-1.5;
  line-height: inherit;

  // Apply same disabled cursor tweak as for inputs
  // Some special care is needed because <label>s don't inherit their parent's `cursor`.
  //
  // Note: Neither radios nor checkboxes can be readonly.
  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    @apply cursor-not-allowed;
  }
}

input[type="file"] {
  @apply block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  @apply block w-full;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  @apply h-auto;
}

// Adjust output element
output {
  @apply block pt-4 text-base text-color;
}


// Common form controls
//
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
//
// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]

.form-control {
  @apply block w-full h-16 py-3 px-5 text-color text-base
    bg-white bg-none border border-gray-300 rounded transition-all duration-150;

  &:focus {
    @apply border-blue;
    outline: 0; // not using Tailwind's outline-none here because it has some weird transition effects
  }

  // Placeholder
  &::-moz-placeholder {
    @apply text-gray-400 opacity-100; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder { @apply text-gray-400; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { @apply text-gray-400; } // Safari and Chrome

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    @apply bg-transparent border-0;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    @apply bg-gray-300 opacity-100;
  }

  &[disabled],
  fieldset[disabled] & {
    @apply cursor-not-allowed;
  }

  // [converter] extracted textarea& to textarea.form-control
}

// Reset height for `textarea`s
textarea.form-control {
  @apply h-auto;
}


// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned. As a workaround, we
// set a pixel line-height that matches the given height of the input, but only
// for Safari. See https://bugs.webkit.org/show_bug.cgi?id=139848
//
// Note that as of 9.3, iOS doesn't support `week`.

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    &.form-control {
      @apply leading-8; // 2rem
    }
  }
}


// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
  @apply mb-6;
}


// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.radio,
.checkbox {
  @apply relative block my-4;

  // These are used on elements with <label> descendants
  &.disabled,
  fieldset[disabled] & {
    label {
      @apply cursor-not-allowed;
    }
  }

  label {
    min-height: theme('spacing.6'); // Ensure the input doesn't jump when there is no text
    @apply pl-8 mb-0 font-normal cursor-pointer;
  }
}
.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  @apply absolute -ml-8;
}

.radio + .radio,
.checkbox + .checkbox {
  @apply -mt-2; // Move up sibling radios or checkboxes for tighter spacing
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
  @apply relative inline-block pl-8 mb-0 font-normal align-middle cursor-pointer;

  // These are used directly on <label>s
  &.disabled,
  fieldset[disabled] & {
    @apply cursor-not-allowed;
  }
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  @apply mt-0 ml-4;
}


// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.

.has-feedback {
  @apply relative;

  // Ensure icons don't overlap text
  .form-control {
    @apply pr-20;
  }
}
// Feedback icon (requires .glyphicon classes)
.form-control-feedback {
  @apply absolute top-0 right-0 z-10 block w-16 h-16 text-center pointer-events-none;
  line-height: 4rem;
}

// Feedback states
.has-success {
  @include tw-form-control-validation(theme('colors.green.800'), theme('colors.green.900'), theme('colors.green.300'));
}
.has-warning {
  @include tw-form-control-validation(theme('colors.yellow.700'), theme('colors.yellow.800'), theme('colors.yellow.300'));
}
.has-error {
  @include tw-form-control-validation(theme('colors.red.500'), theme('colors.red.600'), theme('colors.red.100'));
}

// Reposition feedback icon if input has visible label above
.has-feedback label {

  & ~ .form-control-feedback {
    @apply top-11; // Height of the `label` and its margin
  }
  &.sr-only ~ .form-control-feedback {
    @apply top-0;
  }
}


// Help text
//
// Apply to any element you wish to create light text for placement immediately
// below a form control. Use for general help, formatting, or instructional text.

.help-block {
  @apply block mt-2 mb-4 text-gray-500 text-sm;

  &:last-child {
    @apply mb-0;
  }
}


// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
//
// Heads up! This is mixin-ed into `.navbar-form` in navbars.less.

.form-inline {
  // Kick in the inline
  @media (min-width: theme('screens.sm')) {
    // Inline-block all the things for "inline"
    .form-group {
      @apply inline-block mb-0 align-middle;
    }

    // In navbar-form, allow folks to *not* use `.form-group`
    .form-control {
      @apply inline-block w-auto align-middle;
    }

    .input-group {
      display: inline-table; // Tailwind doesn't have inline-table
      @apply align-middle;

      .input-group-addon,
      .input-group-btn,
      .form-control {
        @apply w-auto;
      }
    }

    // Input groups need that 100% width though
    .input-group > .form-control {
      @apply w-full;
    }

    .control-label {
      @apply mb-0 align-middle;
    }

    // Remove default margin on radios/checkboxes that were used for stacking, and
    // then undo the floating of radios and checkboxes to match.
    .radio,
    .checkbox {
      @apply inline-block mt-0 mb-0 align-middle;

      label {
        @apply pl-0;
      }
    }
    .radio input[type="radio"],
    .checkbox input[type="checkbox"] {
      @apply relative ml-0;
    }

    // Re-override the feedback icon.
    .has-feedback .form-control-feedback {
      @apply top-0;
    }
  }
}

// override the regular legend styles to make it look like a label e.g. for radio button groups
.legend-label {
  margin: 0 0 5px;
  border: 0;
  font-size: inherit;
  font-weight: 600;

  .has-error & {
    color: $brand-danger;
  }
}
