.rating-widget-container {
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 10000;

  width: 400px;
  max-height: 300px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: snow;
  border: 1px solid #f26722;
  border-bottom: none !important;
  padding: 1px;
  box-shadow: 0px 6px 40px rgba(97, 51, 29, 0.35);

  border-radius: 5px 5px 0 0;

  transition: 0.4s ease;
  @media screen and (max-width: $screen-sm) {
    left: 5%;
    width: 90%;
  }
}
#public-order-rating-backdrop {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 5000;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
}
#reveal-public-order-details{
  margin-top: 100px;
  display: none;
}
@keyframes animateShrinkWidget {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}
.animate-shrink-review-container{
  &.rating-widget-container {
    animation-duration: 0.4s;
    animation-name: animateShrinkWidget;
    transition: 0.4s ease;
  }
}
.review-question-input{
  resize: none;
  margin: 15px;
  height: 34px;
  &.form-control {
    height: inherit;
  }
}
.rating-widget-inner-one {
  background-color: #5a5a5a;

  text-align: center;
  font-size: 1.6rem;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3px 3px 0 0;
  @media screen and (max-width: $screen-sm) {
    font-size: 1em;
  }
}
.rating-widget-inner-two, .rating-widget-inner-one {
  width: 100%;
}
.rating-star-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  @media screen and (max-width: $screen-sm) {
    padding-bottom: 20px;
  }
}
.clickable-rating-star-item{
  cursor: pointer;
  font-size: 3em;
  padding-left: 10px;
  padding-right: 10px;
  @media screen and (max-width: $screen-sm) {
    font-size: 2em;
  }
  &:hover {
    text-decoration: none;
  }
  &.star-is-active {
    color: $brand-orange;
  }
  &.star-is-inactive {
    color: lightgrey;
  }
}
#review-complete-copy {
  color: #5a5a5a;
  font-weight: 600;
  font-size: 2em;
  text-align: center;
}
#review-complete-copy-signoff {
  color: #5a5a5a;
  font-weight: 400;
  font-size: 1.2em;
  text-align: center;
}
.review-question-only{
  font-weight: 500;
  color: snow;
  text-align: center;
  padding: 20px 30px 20px 30px;
}
.review-question{
  color: #5a5a5a;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
}
.rating-star-item-and-num{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rating-widget-close-button {
  font-size: 0.8em;
  color:gray;

  position: absolute;
  right: 0;
  top: 0;
}
.next-question-button {
  background-color: white;
  border: 1px solid #54545445;
  align-self: flex-end;
  &.next-button-for-star-rating {
    float: right;
  }
}
.contact-input-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
}
.review-contact-input{
  margin: 0;
}