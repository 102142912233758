.spinner-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  z-index: 1;
}

.spinner-overlay__target {
  position: relative;
}

.glyphicon-refresh-animate {
  -animation: spinn 1s infinite linear;
  -ms-animation: spinn 1s infinite linear;
  -webkit-animation: spinw 1s infinite linear;
  -moz-animation: spinm 1s infinite linear;
}

@keyframes spinn{
  from { transform: scale(1) rotate(0deg);}
  to { transform: scale(1) rotate(360deg);}
}

@-webkit-keyframes spinw {
  from { -webkit-transform: rotate(0deg);}
  to { -webkit-transform: rotate(360deg);}
}

@-moz-keyframes spinm {
  from { -moz-transform: rotate(0deg);}
  to { -moz-transform: rotate(360deg);}
}