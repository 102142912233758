$nav-circle-size-small: 24px;
$nav-circle-size-big: 36px;
$nav-circle-extra-height: 40px;
$nav-circle-line-width: 1px;
$nav-circle-text-width: 80px;
$nav-circle-horizontal-spacing: 20px;
$courier-nav-circle-text-width: 340px;

.nav-circle-container {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  // Give padding on the top for the absolutely positioned dots and lines to display.
  padding-top: $nav-circle-extra-height;

  // Hardcode the height so that we can absolutely position the dots above while
  // still supporting text wrapping on the contained elements.
  height: 40px + $nav-circle-extra-height;
}

// The circle
.nav-circle-circle {
  position: absolute;
  display: inline-block;
  width: $nav-circle-size-small;
  height: $nav-circle-size-small;
  left: 50%;
  top: calc($nav-circle-extra-height / 2);
  margin: 0 - calc($nav-circle-size-small/2);
  border-radius: 24px;
  border: $brand-orange solid $nav-circle-line-width;
  background: white;
  color: white;

  .nav-circles-courier & {
    border: $brand-primary solid $nav-circle-line-width;
    align-items: center;
    justify-content: center;
    display: flex;
    color: black
  }
}

.nav-circle-container--completed .nav-circle-circle {
  background: $brand-orange;
}

.nav-circle-container--active .nav-circle-circle {
  width: $nav-circle-size-big;
  height: $nav-circle-size-big;
  margin: 0 - calc($nav-circle-size-big/2);
  background: $brand-orange;

  .nav-circles-courier & {
    background: $brand-primary;
    color:white
  }
}

// The line
.nav-circle-container:not(:last-child) .nav-circle-circle::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: $nav-circle-horizontal-spacing + $nav-circle-text-width + 5;
  height: $nav-circle-line-width;
  background: $brand-orange;

  .nav-circles-courier & {
    width: $nav-circle-horizontal-spacing + $courier-nav-circle-text-width + 5;
    background-color: $brand-primary;
  }
}

.nav-circle-text {
  display: block;
  margin: 0 $nav-circle-horizontal-spacing;
  padding: 0;
  color: $brand-medium-gray;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .05em;
  font-weight: 500;

  // We hardcode the width so that wrapping will occur on this level. The rest of the
  // layout is brittle to the width of things
  width: $nav-circle-text-width;

  .nav-circles-courier & {
    color: $brand-primary;
    width: $courier-nav-circle-text-width
  }

}

.nav-circle-container--active .nav-circle-text {
  color: $brand-orange;

  .nav-circles-courier & {
    color: $brand-primary;
    font-weight: 800;

  }}

