.cx_delivery_overview__timeline {
  display: flex;
  align-items: center;

  &:before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    background-color: g($color, orange);
    margin-right: 8px;
    margin-top: -2px;
    border-radius: 50%;
  }
}

.cx_delivery_overview__specs {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.cx_delivery_overview__spec {
  &:not(:last-child) {
    margin-bottom: 6px;
  }
}

.cx_delivery_overview__waypoints {
  display: flex;
}

.cx_delivery_overview__waypoint {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    padding-right: 32px;
  }

  > *:first-child {
    margin-bottom: 8px;
  }

  > *:not(:first-child) {
    margin-bottom: 4px;
  }
}