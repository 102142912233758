.cx_b--hr {
  width: 100%;
  border-color: g($color, 'lightGray');
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 0;
}

.cx_b--cursor-pointer {
  cursor: pointer;
}

.cx_b--cursor-not-allowed {
  cursor: not-allowed;
}

.cx_b--list-style-none {
  padding: 0;
  list-style: none;
}

.cx_b--list-style-disc {
  list-style-type: disc;
  padding: 0;
}

.cx_b--background-size-16 {
  background-size: 16px 16px!important;
}
