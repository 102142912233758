// TODO: THIS FILE NEEDS TO BE REDONE.

.cx_waypoint--grid_column {
  text-align: left;
  word-break: break-word;
  align-self: center;
}

.cx_waypoint--grid {
  display: grid;
  grid-template-columns: calc(21%) calc(3%) calc(25%) calc(3%) calc(13%) calc(
      25%
    );
  column-gap: 2%;
}

.cx_waypoint__row--box {
  padding: 5px 10px;
  border: 1px solid g($color, 'white');
  margin: 5px 5px 5px 25px;
  border-radius: 5px;
}

.cx_waypoint--error_flash {
  margin: 5px 5px 5px 25px;
}

.cx_waypoint__span {
  display: inline-block;
  width: 100%;
  line-height: 1.5;
}

.cx_waypoint--vehicle_dropdown {
  max-width: 130px;
  position: relative;
  padding: 5px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgb(23, 43, 77);

  .cx_dropdown--vehicle_option {
    justify-content: space-between;

    .cx_dropdown--vehicle_name {
      margin: 0px auto 0 10px;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .cx_dropdown--vehicle_capacity {
      margin: 0 10px 0 auto;
    }
  }

  .cx_dropdown__trigger {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 95px;
  }

  .cx_dropdown__trigger::after {
    left: calc(100% - 20px);
  }

  .cx_dropdown__item--selected {
    display: none;
  }

  .cx_dropdown__list {
    list-style: none;
    margin: 0px;
    padding: 0px 16px 8px;
    display: none;
    flex-direction: column;
    position: absolute;
    top: calc(100% + 10px);
    left: -1px;
    border: none;
    width: calc(100% + 130px);
    background-color: g($color, 'white');
    max-height: 175px;
    overflow: auto;
    z-index: 1000;

    .cx_field--danger & {
      border-color: g($color, red);
    }

    .cx_field--warning & {
      border-color: g($color, yellow);
    }

    .cx_field--success & {
      border-color: g($color, green);
    }
  }

  .cx_dropdown__list--active {
    display: flex;
  }

  .cx_dropdown__item {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    &:hover {
      font-weight: 700;
    }

    button {
      -webkit-appearance: none;
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      outline: none;
      width: 100%;
    }
  }
}