.cx_accordian {
  position: relative;
  cursor: pointer;
  font-size: 12;
  font-weight: 700;
  color: #061938;
}

.cx_accordian-items {
  animation: reveal 0.5s ease 0s 1 normal;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
