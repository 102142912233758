//
// Alerts
// --------------------------------------------------

// Base styles
// -------------------------

.alert {
  @apply p-6 mb-8 border border-transparent rounded;

  // Headings for larger alerts
  h4 {
    color: inherit; // Specified for the h4 to prevent conflicts of changing heading color
    @apply mt-0;
  }

  // Provide class for links that match alerts
  .alert-link {
    @apply font-bold;
  }

  // Improve alignment and spacing of inner content
  > p,
  > ul {
    @apply mb-0;
  }

  > p + p {
    @apply mt-2;
  }
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissable,
.alert-dismissible {
  @apply pr-16;

  // Adjust close link position
  .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

.alert-success {
  @apply bg-green-200 border-green-300 text-green-800;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.green.200');
    border-color: theme('colors.green.300');
    color: theme('colors.green.800');
  }

  .alert-link {
    @apply text-green-900;
  }
}

.alert-info {
  @apply bg-blue-150 border-blue-200 text-blue-700;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.blue.150');
    border-color: theme('colors.blue.200');
    color: theme('colors.blue.700');
  }

  .alert-link {
    @apply text-blue-800;
  }
}

.alert-warning {
  @apply bg-yellow-200 border-yellow-300 text-yellow-900;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.yellow.200');
    border-color: theme('colors.yellow.300');
    color: theme('colors.yellow.900');
  }

  .alert-link {
    @apply text-yellow-900;
  }
}

.alert-danger {
  @apply bg-orange-100 text-red-500;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.orange.100');
    color: theme('colors.red.500');
  }

  .alert-link {
    @apply text-red-600;
  }
}

.alert-danger-sm {
  @apply bg-orange-100 text-red-500 max-w-4xl flex flex-col mx-auto;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.orange.100');
    color: theme('colors.red.500');
    max-width: 560px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .alert-link {
    @apply text-red-600;
  }
}