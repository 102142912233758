// .w-0 { width: 0; }
// .w-1 { width: 0.25rem; }
// .w-2 { width: 0.5rem; }
// .w-3 { width: 0.75rem; }
// .w-4 { width: 1rem; }
// .w-5 { width: 1.25rem; }
// .w-6 { width: 1.5rem; }
// .w-8 { width: 2rem; }
// .w-10 { width: 2.5rem; }
// .w-12 { width: 3rem; }
// .w-16 { width: 4rem; }
// .w-20 { width: 5rem; }

// .min-w-0 { min-width: 0; }
// .min-w-full { min-width: 100%; }

.max-w-2xs { max-width: 16rem; }
.max-w-xs { max-width: 20rem; }
.max-w-sm { max-width: 24rem; }
.max-w-md { max-width: 28rem; }
.max-w-lg { max-width: 32rem; }
.max-w-xl { max-width: 36rem; }
.max-w-2xl { max-width: 42rem; }
.max-w-3xl { max-width: 48rem; }
.max-w-4xl { max-width: 56rem; }
.max-w-5xl { max-width: 64rem; }
.max-w-6xl { max-width: 72rem; }
.max-w-full { max-width: 100%; }

// .h-0 { height: 0; }
// .h-1 { height: 0.25rem; }
// .h-2 { height: 0.5rem; }
// .h-3 { height: 0.75rem; }
// .h-4 { height: 1rem; }
// .h-5 { height: 1.25rem; }
// .h-6 { height: 1.5rem; }
// .h-8 { height: 2rem; }
// .h-10 { height: 2.5rem; }
// .h-12 { height: 3rem; }
// .h-16 { height: 4rem; }
.h-20 { height: 5rem; }

// .min-h-0 { min-height: 0; }
// .min-h-full { min-height: 100%; }
// .min-h-screen { min-height: 100vh; }

// .max-h-full { max-height: 100%; }
// .max-h-screen { max-height: 100vh; }
