// inspired by https://tailwindcss.com

.tracking-tight {
  letter-spacing: -0.05em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: 0.05em;
}
