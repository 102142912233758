// Layout mixins
// ---------------------------


// Clearfix
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}
