//
// Component animations
// --------------------------------------------------

.fade {
  opacity: 0;
  transition: opacity .15s linear;

  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in { display: block; }
}

tr.collapse.in { display: table-row; }

tbody.collapse.in { display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: .35s;
  transition-timing-function: ease;
}
