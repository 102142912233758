//
// Navbars
// --------------------------------------------------

$_NAVBAR_HEIGHT: 80px;

// Wrapper and base class
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
  min-height: $_NAVBAR_HEIGHT;
  @apply relative mb-8 border border-transparent;
  @include clearfix;

  @media (min-width: theme('screens.sm')) {
    @apply rounded;
  }
}


// Navbar heading
//
// Groups `.navbar-brand` and `.navbar-toggle` into a single component for easy
// styling of responsive aspects.

.navbar-header {
  @include clearfix;

  @media (min-width: theme('screens.sm')) {
    @apply float-left;
  }
}


// Navbar collapse (body)
//
// Group your navbar content into this for easy collapsing and expanding across
// various device sizes. By default, this content is collapsed when <768px, but
// will expand past that for a horizontal display.
//
// To start (on mobile devices) the navbar links, forms, and buttons are stacked
// vertically and include a `max-height` to overflow in case you have too much
// content for the user's viewport.

.navbar-collapse {
  @apply pl-6 pr-6 overflow-x-visible border-t border-transparent;
  -webkit-overflow-scrolling: touch;
  @include clearfix;

  &.in {
    @apply overflow-y-auto;
  }

  @media (min-width: theme('screens.sm')) {
    width: auto;
    border-top: 0;
    box-shadow: none;

    &.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }

    &.in {
      overflow-y: visible;
    }

    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-right: 0;
      padding-left: 0;
    }
  }
}


// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    @apply -ml-6 -mr-6;

    @media (min-width: theme('screens.sm')) {
      @apply mr-0 ml-0;
    }
  }
}


//
// Navbar alignment options
//
// Display the navbar across the entirety of the page or fixed it to the top or
// bottom of the page.

// Static top (unfixed, but 100% wide) navbar
.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;

  @media (min-width: theme('screens.sm')) {
    border-radius: 0;
  }
}


// Brand/project name

.navbar-brand {
  height: $_NAVBAR_HEIGHT;
  @apply float-left py-12 px-6 text-lg;

  &:hover,
  &:focus {
    @apply no-underline;
  }

  > img {
    @apply block;
  }

  @media (min-width: theme('screens.sm')) {
    .navbar > .container &,
    .navbar > .container-fluid & {
      @apply -ml-6;
    }
  }
}


// Navbar toggle
//
// Custom button for toggling the `.navbar-collapse`, powered by the collapse
// JavaScript plugin.

.navbar-toggle {
  @apply relative float-right p-4 my-9 mr-6 bg-transparent bg-none border border-transparent rounded;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    @apply outline-none;
  }

  // Bars
  .icon-bar {
    width: 22px;
    height: 2px;
    @apply block rounded-sm;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  @media (min-width: theme('screens.sm')) {
    @apply hidden;
  }
}


// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  @apply my-6 -mx-6;

  > li > a {
    line-height: 21px;
    @apply py-4;
  }

  @media (max-width: theme('screens.sm-max.max')) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      @apply static float-none w-auto mt-0 bg-transparent border-0 shadow-none;
      > li > a,
      .dropdown-header {
        @apply py-2 pr-6 pl-10;
      }
      > li > a {
        line-height: 21px;
        &:hover,
        &:focus {
          @apply bg-none;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: theme('screens.sm')) {
    @apply float-left m-0;

    > li {
      @apply float-left;
      > a {
        @apply py-12;
      }
    }
  }
}


// Dropdown menus

// Menu position and menu carets
.navbar-nav > li > .dropdown-menu {
  @apply m-0 rounded-none;
}


// Component alignment
//
// Repurpose the pull utilities as their own navbar utilities to avoid specificity
// issues with parents and chaining. Only do this when the navbar is uncollapsed
// though so that navbar contents properly stack and align in mobile.
//
// Declared after the navbar components to ensure more specificity on the margins.

@media (min-width: theme('screens.sm')) {
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
    @apply -mr-6;

    ~ .navbar-right {
      @apply mr-0;
    }
  }
}


// Alternate navbars
// --------------------------------------------------

// Default navbar
.navbar-default {
  @apply bg-white border-b border-gray-300;

  .navbar-brand {
    @apply text-brand-primary;
    &:hover,
    &:focus {
      @apply text-brand-primary bg-transparent;
    }
  }

  .navbar-nav {
    > li > a {
      @apply text-brand-primary;

      &:hover,
      &:focus {
        @apply text-navy-dark bg-transparent;;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        @apply text-brand-primary bg-transparent;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        @apply text-gray-300 bg-transparent;
      }
    }

    // Dropdown menu items
    // Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        @apply text-brand-primary bg-transparent;
      }
    }

    @media (max-width: theme('screens.sm-max.max')) {
      // Dropdowns get custom display when collapsed
      .open .dropdown-menu {
        > li > a {
          @apply text-brand-primary;
          &:hover,
          &:focus {
            @apply text-brand-primary bg-transparent;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            @apply text-brand-primary bg-transparent;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            @apply text-gray-300 bg-transparent;
          }
        }
      }
    }
  }

  .navbar-toggle {
    @apply border-gray-300;
    &:hover,
    &:focus {
      @apply bg-gray-200;
    }
    .icon-bar {
      @apply bg-gray-500;
    }
  }

  .navbar-collapse,
  .navbar-form {
    @apply border-gray-200;
  }


  // Links in navbars
  //
  // Add a class to ensure links outside the navbar nav are colored correctly.

  .navbar-link {
    @apply text-brand-primary;
    &:hover {
      @apply text-brand-primary;
    }
  }

  .btn-link {
    @apply text-brand-primary;
    &:hover,
    &:focus {
      @apply text-brand-primary;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        @apply text-gray-300;
      }
    }
  }
}

// Inverse navbar

.navbar-inverse {
  @apply bg-brand-primary border-navy-dark;

  .navbar-brand {
    @apply text-gray-200;
    &:hover,
    &:focus {
      @apply text-white bg-transparent;
    }
  }

  .navbar-text {
    @apply text-gray-200;
  }

  .navbar-nav {
    > li > a {
      @apply text-gray-200;

      &:hover,
      &:focus {
        @apply text-white bg-transparent;
      }
    }
    > .active > a {
      &,
      &:hover,
      &:focus {
        @apply text-white bg-navy-dark;
      }
    }
    > .disabled > a {
      &,
      &:hover,
      &:focus {
        @apply text-gray-600 bg-transparent;
      }
    }

    // Dropdowns
    > .open > a {
      &,
      &:hover,
      &:focus {
        @apply text-gray-200 bg-navy-dark;
      }
    }

    @media (max-width: theme('screens.sm-max.max')) {
      // Dropdowns get custom display
      .open .dropdown-menu {
        > .dropdown-header {
          @apply border-navy-dark;
        }
        .divider {
          @apply bg-navy-dark;
        }
        > li > a {
          @apply text-gray-200;

          &:hover,
          &:focus {
            @apply text-white bg-transparent;
          }
        }
        > .active > a {
          &,
          &:hover,
          &:focus {
            @apply text-white bg-navy-dark;
          }
        }
        > .disabled > a {
          &,
          &:hover,
          &:focus {
            @apply text-gray-600 bg-transparent;
          }
        }
      }
    }
  }

  // Darken the responsive nav toggle
  .navbar-toggle {
    @apply border-gray-600;
    &:hover,
    &:focus {
      @apply border-gray-600;
    }
    .icon-bar {
      @apply bg-white;
    }
  }

  .navbar-collapse,
  .navbar-form {
    @apply border-navy-dark;
  }

  .navbar-link {
    @apply text-gray-200;
    &:hover {
      @apply text-white;
    }
  }

  .btn-link {
    @apply text-gray-200;
    &:hover,
    &:focus {
      @apply text-white;
    }
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus {
        @apply text-gray-600;
      }
    }
  }
}
