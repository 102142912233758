//
// List groups
// --------------------------------------------------

// Base class
//
// Easily usable on <ul>, <ol>, or <div>.

.list-group {
  @apply pl-0 mb-8;
}

// Individual list items
//
// Use on `li`s or `div`s within the `.list-group` parent.

.list-group-item {
  @apply relative block py-4 px-6 -mb-px bg-white border border-gray-300;

  // Round the first and last items
  &:first-child {
    @apply rounded-t;
  }
  &:last-child {
    @apply mb-0 rounded-b;
  }

  // Disabled state
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    @apply text-gray-500 cursor-not-allowed bg-gray-300;
  }

  // Active class on item itself, not parent
  &.active,
  &.active:hover,
  &.active:focus {
    z-index: 2; // Place active items above their siblings for proper border styling
    @apply text-brand-primary bg-white border-white;
  }
}

// Interactive list items
//
// Use anchor or button elements instead of `li`s or `div`s to create interactive items.
// Includes an extra `.active` modifier class for showing selected items.

a.list-group-item,
button.list-group-item {
  @apply text-gray-500;

  // Hover state
  &:hover,
  &:focus {
    @apply text-gray-700 no-underline bg-white;
  }
}

button.list-group-item {
  @apply w-full text-left;
}
