.cx_l--container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 768px) {
    & {
      width: 750px;
    }
  }

  @media (min-width: 992px) {
    & {
      width: 970px;
    }
  }

  @media (min-width: 1200px) {
    & {
      width: 1170px;
    }
  }
}

.cx_l--hidden-important {
  display: none !important;
}

.cx_l--display-inline-block {
  display: inline-block !important;
}

.cx_l--text-align-right {
  text-align: right;
}

.cx_l--flex {
  display: flex !important;
}

.cx_l--flex-column {
  flex-direction: column;
}

.cx_l--flex-display-inline {
  display: inline-flex !important;
}

.cx_l--flex-align-start {
  align-items: flex-start;
}

.cx_l--flex-align-center {
  align-items: center;
}

.cx_l--flex-align-end {
  align-items: flex-end;
}

.cx_l--flex-justify-start {
  justify-content: flex-start;
}

.cx_l--flex-justify-center {
  justify-content: center;
}

.cx_l--flex-justify-end {
  justify-content: flex-end;
}

.cx_l--flex-justify-between {
  justify-content: space-between;
}

.cx-l--flex-shrink-0 {
  flex-shrink: 0;
}

.cx-l--flex-wrap {
  flex-wrap: wrap;
}

.cx-l--flex-nowrap {
  flex-wrap: nowrap;
}

.cx-l--flex-grow-1 {
  flex-grow: 1;
}

.cx_l--width-auto {
  width: auto !important;
}

@for $i from 1 through 100 {
  @if ($i % 5 == 0) {
    .cx_l--width-#{$i} {
      width: #{$i + '%'} !important;
    }
  }
}

@for $i from 0 through 140 {
  @if ($i % 2 == 0) {
    .cx_l--width-#{$i}px {
      width: #{$i + 'px'} !important;
    }

    .cx_l--min-width-#{$i}px {
      min-width: #{$i + 'px'} !important;
    }
  }
}

@for $i from 0 through 64 {
  @if ($i % 2 == 0) {
    .cx_l--height-#{$i}px {
      height: #{$i + 'px'} !important;
    }
  }
}

@each $height in [150, 225, 280, 350, 433, 550] {
  .cx_l--height-#{$height} {
    height: #{$height + 'px'};
  }
}

.cx_l--height-screen {
  height: calc(100vh - 221px);
}

.cx_l--height-inherit {
  height: inherit;
}

.cx_l--width-400 {
  width: 400px;
}

.cx_l--width-600 {
  width: 600px !important;
}

.cx_l--width-725 {
  width: 725px !important;
}

.cx_l--width-fit-content {
  width: fit-content !important;
}

@for $i from 1 through 64 {
  @if ($i % 4 == 0) {
    .cx_l--flex-gutter-right-#{$i} > *:not(:last-child) {
      margin-right: #{$i + 'px'} !important;
    }

    .cx_l--flex-gutter-bottom-#{$i} > *:not(:last-child) {
      margin-bottom: #{$i + 'px'} !important;
    }
  }
}

.cx_l--margin-left-auto {
  margin-left: auto !important;
}

@for $i from 0 through 64 {
  @if ($i < 16 and $i % 1 == 0) {
    .cx_l--padding-#{$i} {
      padding: #{$i + 'px'} !important;
    }

    .cx_l--padding-left-#{$i} {
      padding-left: #{$i + 'px'} !important;
    }

    .cx_l--padding-right-#{$i} {
      padding-right: #{$i + 'px'} !important;
    }

    .cx_l--padding-top-#{$i} {
      padding-top: #{$i + 'px'} !important;
    }

    .cx_l--padding-bottom-#{$i} {
      padding-bottom: #{$i + 'px'} !important;
    }

    .cx_l--margin-left-#{$i} {
      margin-left: #{$i + 'px'} !important;
    }

    .cx_l--margin-right-#{$i} {
      margin-right: #{$i + 'px'} !important;
    }

    .cx_l--margin-top-#{$i} {
      margin-top: #{$i + 'px'} !important;
    }

    .cx_l--margin-top--#{$i} {
      margin-top: #{-$i + 'px'} !important;
    }

    .cx_l--margin-bottom-#{$i} {
      margin-bottom: #{$i + 'px'} !important;
    }

    .cx_l--margin-bottom--#{$i} {
      margin-bottom: #{-$i + 'px'} !important;
    }
  }

  @if ($i > 15 and $i % 2 == 0) {
    .cx_l--padding-#{$i} {
      padding: #{$i + 'px'} !important;
    }

    .cx_l--padding-left-#{$i} {
      padding-left: #{$i + 'px'} !important;
    }

    .cx_l--padding-right-#{$i} {
      padding-right: #{$i + 'px'} !important;
    }

    .cx_l--padding-top-#{$i} {
      padding-top: #{$i + 'px'} !important;
    }

    .cx_l--padding-bottom-#{$i} {
      padding-bottom: #{$i + 'px'} !important;
    }

    .cx_l--margin-left-#{$i} {
      margin-left: #{$i + 'px'} !important;
    }

    .cx_l--margin-left--#{$i} {
      margin-left: #{-$i + 'px'} !important;
    }

    .cx_l--margin-right-#{$i} {
      margin-right: #{$i + 'px'} !important;
    }

    .cx_l--margin-right--#{$i} {
      margin-right: #{-$i + 'px'} !important;
    }

    .cx_l--margin-top-#{$i} {
      margin-top: #{$i + 'px'} !important;
    }

    .cx_l--margin-bottom-#{$i} {
      margin-bottom: #{$i + 'px'} !important;
    }
  }
}

.cx_l-margin-left-neg-6 {
  margin-left: -6px !important;
}

.cx_l--margin-auto {
  margin: auto !important;
}

.cx_l--margin-left-auto {
  margin-left: auto !important;
}

.cx_l--position-relative {
  position: relative !important;
}

.cx_l--position-absolute {
  position: absolute !important;
}

.cx_l--position-sticky {
  position: sticky !important;
}

@for $i from 0 through 50 {
  @if ($i % 2 == 0) {
    .cx_l--left-#{$i} {
      left: #{$i + 'px'} !important;
    }
  }
}

@for $i from 0 through 50 {
  @if ($i % 2 == 0) {
    .cx_l--right-#{$i} {
      right: #{$i + 'px'} !important;
    }
  }
}

@for $i from 0 through 50 {
  @if ($i % 2 == 0) {
    .cx_l--top--#{$i} {
      top: #{-$i + 'px'} !important;
    }
  }
}

.cx_l--float-left {
  float: left !important;
}

.cx_l--float-right {
  float: right !important;
}


@each $max-width in [320] {
  .cx_l--max-width-#{$max-width} {
    max-width: #{$max-width + 'px'};
  }
}

.max-height-screen {
   max-height: calc(100vh - 221px);
}

.cx_l--width-max-content {
  width: max-content;
}

@for $i from 0 through 25 {
  @if ($i % 1 == 0) {
    .cx_l--max-width-#{$i}ch {
      max-width: #{$i + 'ch'} !important;
    }
  }
}

@for $i from 0 through 25 {
  @if ($i % 1 == 0) {
    .cx_l--width-#{$i}ch {
      width: #{$i + 'ch'} !important;
    }
  }
}

@for $i from 0 through 100 {
  @if ($i % 1 == 0) {
    .cx_l--height-#{$i} {
      height: #{$i + 'px'} !important;
    }
  }
}

.cx_l--scroll-x {
  overflow-x: scroll;
}

.cx_l--scroll-y {
  overflow-y: scroll;
}

.cx_l--overflow-hidden {
  overflow: hidden;
}

.cx_l--overflow-auto {
  overflow: auto;
}

.cx_l--white-space-nowrap {
  white-space: nowrap;
}

.cx_l--white-space-pre {
  white-space: pre;
}

.cx_outline--none {
  outline: none;
}
