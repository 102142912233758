//
// Navs
// --------------------------------------------------

// Base class
// --------------------------------------------------

.nav {
  @apply pl-0 pb-0 list-none;
  @include clearfix;

  > li {
    @apply relative block;

    > a {
      @apply relative block py-4 px-6;
      &:hover,
      &:focus {
        text-decoration: none;
        @apply no-underline bg-gray-100;
      }
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      @apply text-gray-400;

      &:hover,
      &:focus {
        @apply text-gray-400 no-underline cursor-not-allowed bg-transparent;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      @apply bg-gray-200 border-link;
    }
  }
}

// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  @apply border-b border-gray-300;
  > li {
    @apply float-left -mb-px;

    // Actual tabs (as links)
    > a {
      @apply text-gray-800 mr-1 rounded-t;
      border-bottom: 0;
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      @apply rounded-t border border-gray-300;
      border-bottom-color: transparent;

      &,
      &:hover,
      &:focus {
        @apply text-brand-primary cursor-default bg-white;
      }
    }
  }
}

// Pills
// -------------------------
.nav-pills {
  > li {
    @apply float-left;

    // Links rendered as pills
    > a {
      @apply rounded;
    }
    + li {
      @apply ml-1;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        @apply text-brand-primary bg-gray-100;
      }
    }
  }
}

// Stacked pills
.nav-stacked {
  > li {
    @apply float-none;
    + li {
      @apply mt-1 ml-0;
    }
  }
}

// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  @apply rounded-t-none;
}
