@mixin animation($delay, $duration, $animation) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(cx-ellipsis-loading) {
  0% {
    width: 0px;
  }
  24.9% {
    width: 0px;
  }
  25% {
    width: 3px;
  }
  49.9% {
    width: 3px;
  }
  50% {
    width: 6px;
  }
  74.9% {
    width: 6px;
  }
  75% {
    width: 10px;
  }
  100% {
    width: 10px;
  }
}

@include keyframes(cx-ellipsis-loading-plan-it) {
  0% {
    width: 0px;
  }
  24.9% {
    width: 0px;
  }
  25% {
    width: 10px;
  }
  49.9% {
    width: 10px;
  }
  50% {
    width: 20px;
  }
  74.9% {
    width: 20px;
  }
  75% {
    width: 30px;
  }
  100% {
    width: 30px;
  }
}

@include keyframes(cx-floptimize-truck-spin) {
  from {
    transform: rotate(0deg) translate(0px, -75px);
    left: 287px;
  }
  25% {
    transform: rotate(90deg) translate(0px, -75px);
    left: 275px;
  }
  50% {
    transform: rotate(180deg) translate(0px, -75px);
    left: 287px;
  }
  75% {
    transform: rotate(270deg) translate(0px, -75px);
    left: 299px;
  }
  to {
    transform: rotate(360deg) translate(0px, -75px);
    left: 287px;
  }
}

@include keyframes(cx-plan-it-lines) {
  50% {
    height: 300px;
    width: 300px;
    top: calc(47vh - 150px);
    left: calc(50vw - 150px);
  }
}
