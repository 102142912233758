.list-divided {
  > * {
    display: inline-block;
    padding-right: 1em;
    margin-right: .75em; // less than the padding to counteract the whitespace character
    border-right: 1px solid $neutral-300;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }
  }
}

.list-plain {
  list-style: none;
  margin: 0;
  padding: 0;

  > * {
    margin: 0;
    padding: 0;
  }
}
