// An index of z-index values used throughout the site
//
// Organize these by number (high to low) rather than name

$z-tooltip: 1070;
$z-popover: 1060;
$z-modal: 1050;
$z-modal-background: 1040;
$z-above-navbar-alert: 1035;
$z-navbar-fixed: 1030;
$z-navbar: 1000;
$z-dropdown: 1000;
$z-dropdown-backdrop: 990;

$z-input-group-control-active: 3;
$z-input-group-control: 2;
$z-btn-group-active: 2;
