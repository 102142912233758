.segment {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;

  &:before {
    @apply bg-gray-200;
    content: '';
    width: 4px;
    position: absolute;
    top: 40px;
    left: 16px;
    bottom: -15px;
    border-radius: 10px;
  }
}

.segment--in-progress {
  &:before {
    @apply bg-brand-primary;
  }
}

.segment--dashed {
  &:before {
    background: none;
    border: 1px dashed $gray-base;
    bottom: -18px;
    top: 23px;
    width: 0;
  }
}

.segment + .segment {
  margin-top: 20px;
}

.segment:last-child {
  &:before {
    content: none;
  }
}

.segment__icon {
  @apply text-white fill-current text-lg bg-brand-primary;
  width: 36px;
  height: 36px;
  flex: 0 0 auto;
  border: 8px solid theme('colors.brand-primary');
  border-radius: 30px;
  background-color: $brand-primary;

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .segment--incomplete & {
    @apply bg-gray-200 border-gray-200;
  }
}

.segment__icon--transparent {
  background: none;
  border: none;
}

.segment__icon--circle-pin {
  padding-left: 7px;
}

.segment__icon--pointer-pin {
  margin-top: -3px;
  padding-left: 2px;
}

.segment__content {
  margin-left: 12px;
  min-width: 0; // annoying flexbox thing to allow child elements to use overflow:hidden
}
