// Base element styles, overriding Tailwind

@layer base {
  html {
    font-size: 10px; // This makes 1rem = 10px
  }

  body {
    @apply font-sans text-base text-color bg-base;

    &.marketplace-react {
      @apply bg-gray-50;
    }
  }

  a {
    @apply text-link;

    // This media query specifically targets IE10+
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      color: theme('colors.link.DEFAULT');
    }

    &:hover, &:focus {
      @apply text-link-hover underline;

      // This media query specifically targets IE10+
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        color: theme('colors.link.hover');
      }
    }
  }

  hr {
    @apply my-8;
  }

  pre {
    @apply overflow-auto;
  }
}
