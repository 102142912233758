.cx_tabs {
  display: flex;
  box-shadow: g($shadow, under0);
}

.cx_tabs__item {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;

  padding: 8px 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  background-color: g($color, 'white');
  color: g($color, 'blue');
  box-shadow: g($shadow, under0);
  cursor: pointer;
  outline: 0;

  &:hover {
    color: g($color, 'blue');
  }

  &:focus,
  &:active {
    color: g($color, 'blue');
    box-shadow: g($shadow, around0);
  }
}

.cx_tabs__item--active {
  color: g($color, 'blue');
  box-shadow: g($shadow, under1);
}