.cx_t {
  vertical-align: middle;

  display: inline-block;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  color: g($color, 'darkGray');
  font-weight: 400;
  text-align: left;

  &:hover {
    text-decoration: none;
  }
}

.cx_t--xxl {
  font-size: 36px;
  line-height: 44px;
}

.cx_t--xl {
  font-size: 30px;
  line-height: 32px;
}

.cx_t--l {
  font-size: 26px;
  line-height: 32px;
}

.cx_t--md {
  font-size: 24px;
  line-height: 20px;
}

.cx_t--m {
  font-size: 16px;
  line-height: 24px;
}

.cx_t--s {
  font-size: 20px;
  line-height: 24px;
}

.cx_t--xs {
  font-size: 14px;
  line-height: 16px;
}

.cx_t--xxs {
  font-size: 12px;
  line-height: 16px;
}

.cx_t--xxxs {
  font-size: 10px;
}

.cx_t--200 {
  font-weight: 200 !important;
}

.cx_t--400 {
  font-weight: 400 !important;
}

.cx_t--300 {
  font-weight: 300 !important;
}

.cx_t--600 {
  font-weight: 600 !important;
}

.cx_t--700 {
  font-weight: 700 !important;
}

.cx_t--800 {
  font-weight: 800 !important;
}

.cx_t--line-height-1-25 {
  line-height: 1.25;
}

@for $i from 0 through 24 {
  .cx_t--line-height-#{$i}px {
    line-height: #{$i + 'px'};
  }
}

@for $i from 8 through 50 {
  .cx_t--font-size-#{$i}px {
    font-size: #{$i + 'px'} !important;
  }
}

.cx_t--text-align-center {
  text-align: center;
}

.cx_t--no-wrap {
  white-space: nowrap;
}

.cx_t--break-spaces {
  white-space: break-spaces;
}

.cx_t--underline {
  text-decoration: underline !important;
}

.cx_t--underline-on-hover:hover {
  text-decoration: underline !important;
}

.cx_t--blue-on-hover {
  &:hover,
  &:active,
  &:focus {
    color: g($color, 'blue') !important;
  }
}

@for $i from 5 through 70 {
  @if ($i % 5 == 0) {
    .cx_t--limit-#{$i} {
      max-width: #{$i + 'ch'};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.cx_t--xlimit-25 {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
}

@each $c, $h in $color {
  .cx_t--#{$c} {
    color: $h !important;
  }
}

.cx_t--text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.cx_t--text-tooltip-hover {
  &:not(.redesign){
    position: relative;
  }
}

.cx_t--text-tooltip-hover .cx_t--tooltiptext-container {
  visibility: hidden;
  background-color: #fff;
  padding: 0px 8px;
  border-radius: 3px;
  width: auto;
  left: -8px;
  position: absolute;
  z-index: 11;
}

.cx_t--text-tooltip-hover:hover .cx_t--tooltiptext-container {
  visibility: visible;
  transition-delay: 0.5s;
}

.cx_t--tooltip-text-link {
  color: g($color, 'darkBlue') !important;
  text-decoration: underline !important;
}
