.cx_map-pin__tooltip {
  background-color: g($color, 'blue');
  color: g($color, 'white');
  font-size: 10px;
  font-weight: 600;
  left: 50%;
  margin-top: -10px;
  padding: 0 4px;
  position: absolute;
  text-align: center;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.cx_map-pin__tooltip:after {
  border-color: g($color, 'blue') transparent transparent transparent;
  border-style: solid;
  border-width: 4px;
  content: '';
  left: 50%;
  margin-left: -5px;
  position: absolute;
  top: 100%;
}

.cx_map-pin-zipCode__tooltip {
  background-color: g($color, 'white') !important;
  top: 50% !important;
  color: #000000 !important;
}

.cx_map-pin-zipCode__tooltip::after{
  border-color: g($color, 'white') transparent transparent transparent;
}

.cx_map-pin-customer__tooltip {
  background-color: g($color, 'white') !important;
  color: #000000 !important;
  top: -5px !important;
  left: 6px !important;
}

.cx_map-pin-customer__tooltip::after {
  border-color: g($color, 'white') transparent transparent transparent;
  border-width: 6px !important;
  top: 93% !important;
}

.cx_map-pin-customer__tooltip::before {
    border-color: g($color, 'blue') transparent transparent transparent;
    border-style: solid;
    border-width: 6px;
    content: '';
    left: 50%;
    position: absolute;
    top: 100%;
    margin-left: -5px;
}

.cx_map-pin-customer__tooltip {
  border: 1px solid g($color, 'blue');
}
