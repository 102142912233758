// blocks - a layout tool that stacks a list of items horizontally
// to fit a certain number across the screen, fluidly adjusting width.
// Unlike columns, layout order is horizontal rather than vertical

$_blocks-gutter-width: 15px;

// block sizes are represented as a fractional value
// e.g. .blocks-4 would be 1/4 of the whole column
$_sizes: 2, 3, 4;

// these are the breakpoints that will have corresponding classes
// e.g. .blocks-md-4 will apply the 1/4 column sizing at screen sizes of $screen-md and up
$_breakpoints: (
  'sm': $screen-sm,
  'md': $screen-md,
  'lg': $screen-lg
);

.blocks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -($_blocks-gutter-width);

  > * {
    margin-left: $_blocks-gutter-width;
    margin-bottom: $_blocks-gutter-width;
    width: calc(100% - #{$_blocks-gutter-width});
  }
}

@each $size in $_sizes {
  .blocks-#{$size} > * {
    width: calc(#{percentage(calc(1 / $size))} - #{$_blocks-gutter-width})
  }
}

@each $breakpoint-name, $breakpoint in $_breakpoints {
  @each $size in $_sizes {
    @media (min-width: $breakpoint) {
      .blocks-#{$breakpoint-name}-#{$size} > * {
        width: calc(#{percentage(calc(1 / $size))} - #{$_blocks-gutter-width})
      }
    }
  }
}
