.radio-button {
  @apply font-normal mr-4;

  position: relative;
}

.radio-button__input {
  opacity: 0;
  left: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  z-index: 1;
  cursor: pointer;

  &:disabled,
  &[readonly] {
    cursor: not-allowed;
  }
}

.radio-button__label {
  &::before {
    @apply mr-3;

    content: '';
    display: inline-block;
    top: 2px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
    border: 1px solid #cdcdcd;
    position: relative;
  }
}

.radio-button__input:checked + .radio-button__label::before {
  border: 4px solid #014e7d;
}

// :focus-visible targets keyboard interactions only, but only works in some browsers
.radio-button__input:focus-visible + .radio-button__label::before {
  @apply ring;
}
