/* Customize the label (the roles-checkbox-container) */
.roles-checkbox-container .unchecked {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border: solid #999;
  border-width: 1px;
  border-radius: 10%;
}

.roles-checkbox:hover .unchecked {
    background-color: #eee;
}

.roles-checkbox-container .checked {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #000;
  border: solid #000;
  border-width: 1px;
  border-radius: 10%;
  cursor: pointer;
}

/* On mouse-over, add a grey background color */
.roles-checkbox-container:hover input ~ .unchecked {
  background-color: #ccc;
}

/* Style the roles-checkbox-checkmark/indicator */
.roles-checkbox-container .checkmark {
  cursor: pointer;
  left: 11px;
  top: 3px;
  width: 9px;
  height: 21px;
  border: solid #fff;
  border-width: 0px 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(40deg);
  position: absolute;
}
