// Overrides for the react-datepicker default css

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  font-size: 1.4rem;
}

.react-datepicker__navigation {
  height: 20px;
  width: 20px;
  border-width: 10px;
}

.react-datepicker__current-month {
  font-size: inherit;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 3.6rem;
  line-height: 3.6rem;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__day--highlighted {
  @apply bg-blue-100 text-color;

  &:hover {
    @apply bg-blue-200;
  }
}
