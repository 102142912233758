.cx_border--dashed {
  border: 1px dashed #BDBDBD;
}

@for $i from 1 through 3 {
  .cx_border-width--#{$i} {
    border: #{$i + 'px'} solid !important;
  }
}

@each $c, $h in $color {
  .cx_border--#{$c} {
    border-color: $h !important;
  }
}
