.justify-start { justify-content: flex-start; }
.justify-center { justify-content: center; }
.justify-end { justify-content: flex-end; }
.justify-between { justify-content: space-between; }
.justify-around { justify-content: space-around; }

.flex-row { flex-direction: row; }
.flex-column { flex-direction: column; }

.align-items-start { align-items: flex-start; }
.align-items-center { align-items: center; }
.align-items-stretch { align-items: stretch; }
.align-items-baseline { align-items: baseline; }
.align-items-end { align-items: flex-end; }
