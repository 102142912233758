@mixin tw-reset-text() {
  font-family: theme('fontFamily.sans');
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: 400;
  @apply leading-normal;
  line-break: auto;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
}
