.media {
  // Proper spacing between instances of .media
  @apply mt-6;

  &:first-child {
    @apply mt-0;
  }
}

.media,
.media-body {
  @apply overflow-hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  @apply block;

  // Fix collapse in webkit from max-width: 100% and display: table-cell.
  &,
  & img,
  &.img-thumbnail {
    @apply max-w-none;
  }
}

.media-right,
.media > .pull-right {
  @apply pl-4;
}

.media-left,
.media > .pull-left {
  @apply pr-4;
}

.media-left,
.media-right,
.media-body {
  @apply table-cell align-top;
}

.media-middle {
  @apply align-middle;
}

.media-bottom {
  @apply align-bottom;
}

// Reset margins on headings for tighter default spacing
.media-heading {
  @apply mt-0 mb-2;
}

// Media list variation
//
// Undo default ul/ol styles
.media-list {
  @apply pl-0 list-none;
}
