@import '../utils/animation.scss';

.cx_vehicle-panel__driver-label {
  position: relative;
  float: left;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: black !important;
}

.cx_dropdown__trigger {
  &:after {
    .cx_vehicle-panel__dropdown &,
    .cx_vehicle-panel__dropdown-outline & {
      width: 18px;
      height: 18px;
      color: g($color, 'blue') !important;
    }
  }

  &.redesign.read-only {
    &:after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgICAgPG1hc2sgaWQ9Im1hc2swIiBtYXNrLXR5cGU9ImFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIzIiB5PSI1IiB3aWR0aD0iMTAiIGhlaWdodD0iNiI+CiAgICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMy41Mjg3NiA1LjUyODU5QzMuNzg5MTEgNS4yNjgyNCA0LjIxMTIyIDUuMjY4MjQgNC40NzE1NyA1LjUyODU5TDguMDAwMTYgOS4wNTcxOUwxMS41Mjg4IDUuNTI4NTlDMTEuNzg5MSA1LjI2ODI0IDEyLjIxMTIgNS4yNjgyNCAxMi40NzE2IDUuNTI4NTlDMTIuNzMxOSA1Ljc4ODk0IDEyLjczMTkgNi4yMTEwNSAxMi40NzE2IDYuNDcxNEw4LjQ3MTU3IDEwLjQ3MTRDOC4yMTEyMiAxMC43MzE3IDcuNzg5MTEgMTAuNzMxNyA3LjUyODc2IDEwLjQ3MTRMMy41Mjg3NiA2LjQ3MTRDMy4yNjg0MSA2LjIxMTA1IDMuMjY4NDEgNS43ODg5NCAzLjUyODc2IDUuNTI4NTlaIiBmaWxsPSIjNDI1MjZFIi8+CiAgICAgIDwvbWFzaz4KICAgICAgPGcgbWFzaz0idXJsKCNtYXNrMCkiPgogICAgICA8cmVjdCB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9IiM3MzcxNzMiLz4KICAgICAgPC9nPgogICAgICA8L3N2Zz4KICAgICAg');
    }
  }

  &:not(.redesign) {
    &:after {
      .cx_vehicle-panel__dropdown & {
        top: calc(50% - 11px);
        left: calc(100% - 20px);
      }
  
      .cx_vehicle-panel__dropdown-outline & {
        top: calc(50% - 19px);
        left: calc(100% - 24px);
      }
    }
  }
  
}

.cx_vehicle-panel__dropdown-outline {
  float: right;
  bottom: 6px;
  font-weight: 400px !important;
  border-radius: 3px;
  border: 1px solid g($color, 'blue') !important;
  position: static !important;

  .cx_dropdown__list {
    top: calc(100% - 20px) !important;
    left: 45px !important;

    &.redesign {
      top: 100% !important;
      left: 0 !important;
      max-width: 250px;
    }
  }
}

.cx_vehicle-panel__dropdown {
  position: relative;
  float: right;
  bottom: 6px;
  font-weight: 400px !important;
  border-bottom: none !important;
}

.cx_vehicle-panel__edit-pen {
  background-position: 4px center;
  margin-top: -2px;
}

.pickup_complete {
  svg {
    margin-top: 5px;
    margin-left: -4px;
  }
}

.cx_vertical-solid-line {
  border-left: 2px solid black;
  position: absolute;
  z-index: 0;
  margin-left: 29px;
  margin-top: 15px;
}

.truck-container {
  display: flex;
  align-items: center;
  margin-left: 7px;

  &.redesign {
    margin-left: -9px;
  }

  span.truck-eta {
    width: 15px;
    border-top: 2px solid black;
    margin-left: -15px;
    margin-top: -8px;
  }

  svg {
    z-index: 1;
  }

  p {
    margin-top: 3px;
    margin-left: 2px;
    font-weight: 600;
  }
}

.cx_vertical-dotted-line {
  border-left: 1px dotted black;
  position: absolute;
  z-index: 0;
  margin-left: 30px;
  margin-top: 16px;
}

.cx_vertical-dashed-line {
  border-left: 1px dashed black;
  position: absolute;
  z-index: 0;
  margin-left: 30px;
  margin-top: 16px;
}

.cx_vehicle-panel__route-details-input {
  height: 36px;
  width: 346px;
  border-color: #cecbcf !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding-left: 10px;
  color: g($color, 'darkBlue');
  font-weight: 600;
}

.cx_vehicle-panel-v2__route-edit-name-details {
  display: flex;
}

.cx_vehicle-panel__route-details-edit-div {
  display: flex;
  margin-bottom: 10px;
}

.cx_vehicle-panel__edit-button-disabled {
  border-color: #e8e4e9 !important;
  background-color: #e8e4e9 !important;
  color: white !important;
}

.cx_vehicle-panel__completed-route-pickup {
  position: relative;
  left: -22px;
  height: 40px;
  margin-bottom: -8px;
}

.cx_vehicle-panel__completed-route-view-link {
  margin-top: -6px;
}

.cx_vehicle-panel-ro-white-out {
  background: rgba(255, 255, 255, 0.61) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 618px;
}

.cx_vehicle-panel__floptimize-truck {
  height: 44px;
  width: 44px;

  &:not(.redesign) {
    position: absolute;
    left: 287px;
  }

  @include animation(0s, 2s, cx-floptimize-truck-spin);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cx_vehicle-panel__floptimize-animation-circle {
  height: 118px;
  width: 120px;
  border: 10px solid g($color, 'blue');
  border-radius: 50%;
  
  &:not(.redesign) {
    position: absolute;
    left: 249px;
  }
}

.cx_vehicle-panel__floptimize-text {
  height: 18px;
  
  &:not(.redesign) {
    position: absolute;
    left: 273px;
  }
}

.cx_vehicle-panel__floptimize-text-dots {
  height: 18px;
  overflow: hidden;
  width: 0px;

  &:not(.redesign) {
    position: absolute;
    left: 343px;
  }

  @include animation(0s, 3s, cx-ellipsis-loading);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cx_vehicle-panel__errors-title {
  position: relative;
  width: 419px;
  height: 40px;
  left: 12px;
  line-height: 20px;
}

.cx_vehicle-panel__stop-error-text {
  position: absolute;
  right: 1px;
  width: auto;
  margin-bottom: 66px;
}

.cx_delivery_images {
  width: 120px;
  padding-top: 20px;
}
