.checklist {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.checklist__item {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  &:before {
    content: '';
    flex: 0 0 auto;
    height: 16px;
    width: 16px;
    border: 1px solid $gray-lightest;
    margin-right: 8px;
    border-radius: 100px; // pill/circle shaped at any size up to 200px
  }
}

.checklist__item--flex-start {
  align-items: flex-start;

  &:before {
    margin-top: 2px;
  }
}

.checklist__item--true,
.checklist__item--false {
  &:before {
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
  }
}

.checklist__item--true {
  &:before {
    @apply bg-brand-primary border-brand-primary;
    background-image: url('~assets/images/whiteCheck.svg');
  }
}

.checklist__item--false {
  &:before {
    background-image: url('~assets/images/redX.svg');
    background-color: rgba($brand-red, .4);
    border-color: rgba($brand-red, .4);
  }
}

.checklist__item--invisible {
  &:before {
    content: none;
  }
}

.checklist__item-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
