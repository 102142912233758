.cx_toasts {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 99999;
}

.cx_toast {
  padding: 20px;
  border: 1px solid g($color, 'gray');
  box-shadow: g($shadow, 'elevation3');
  border-radius: 3px;
  width: 370px;
  display: flex;
  background-color: g($color, 'white');
  position: relative;
  margin-bottom: 32px;
  min-height: 105px;
}

.cx_toast__aside {
  width: 12%
}

.cx_toast__title {
  color: g($color, 'darkBlue');
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 8px;
  padding: 0;
  max-width: 25ch;
}

.cx_toast__content {
  color: g($color, 'darkBlue');
  font-size: 14px;
  line-height: 20px;
  max-width: 30ch;
}

.cx_toast__link {
  color: g($color, 'lightBlue');
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.cx_toast__close {
  position: absolute;
  top: 14px;
  right: 14px;

  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
}
