.table--diagonal-header {
  width: auto;
  border: 0;
}

.table--diagonal-header > thead > tr > th {
  border: 0;
}

.diagonal-header {
  height: 160px;
  white-space: nowrap;
}

.table-bordered > thead > tr > th.diagonal-header {
  border: 0;
  padding: 0;
}

.diagonal-header > div {
  transform: translate(30px, -12px) rotate(-45deg);
  width: 45px;
  border-bottom: 1px dashed #ddd;
  padding: 0 12px 6px 2px;
}
