//
// Labels
// --------------------------------------------------

.label {
  // 75% means it is relative to the text size wherever it is placed
  font-size: 75%;
  @apply inline px-3 py-1 leading-none font-bold text-white text-center whitespace-nowrap align-baseline rounded;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    color: theme('colors.white');
  }

  // [converter] extracted a& to a.label

  // Empty labels collapse automatically
  &:empty {
    @apply hidden;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}

// Add hover effects, but only for links
a.label {
  &:hover,
  &:focus {
    @apply no-underline cursor-pointer;
  }
}

// Colors

.label-default {
  @apply bg-gray-400;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.gray.400');
  }
}

.label-success {
  @apply bg-green;

  // This media query specifically targets IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    background-color: theme('colors.green.DEFAULT');
  }
}
