@import '../utils/animation.scss';

.cx_dashboard__plan-it-animation-box {
  position: fixed;
  height: 93px;
  width: 93px;
  top: calc(47vh - 46.5px);
  left: calc(50vw - 46.5px);
}

.cx_dashboard__plan-it-animation-lines {
  position: fixed;
  height: 120px;
  width: 120px;
  top: calc(47vh - 60px);
  left: calc(50vw - 60px);
}

.cx_dashboard__plan-it-animation-line-12 {
  @include animation(0s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-130 {
  @include animation(0.3s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-3 {
  @include animation(0.6s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-430 {
  @include animation(0.9s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-6 {
  @include animation(1.2s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-730 {
  @include animation(1.5s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-9 {
  @include animation(1.8s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.cx_dashboard__plan-it-animation-line-1030 {
  @include animation(2.1s, 1.5s, cx-plan-it-lines);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cx_dashboard__plan-it-gray-out {
  background: rgba(0, 0, 0, 0.32) !important;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
}

.cx_dashboard__plan-it-text-dots {
  position: fixed;
  width: 0px;
  top: calc(47vh + 150px);
  left: calc(50vw + 416px);
  overflow: hidden;

  @include animation(0s, 3s, cx-ellipsis-loading-plan-it);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.cx_dashboard__plan-it-text {
  position: fixed;
  width: 828px;
  top: calc(47vh + 150px);
  left: calc(50vw - 414px);
}

.cx_dashboard__late-error-icon {
  margin-left: -3px;
  margin-top: -10px;
  margin-right: 4px;
}

.cx_dashboard__loading-spinner-brand-navy {
  &:before {
    border-top-color: #003A5D;
  }
}

.cx_dashboard__right-aside {
  padding-top: 108px;
}
