//
// Tables
// --------------------------------------------------


table {
  @apply bg-transparent;
  border-spacing: 0;

  // Table cell sizing
  //
  // Reset default table behavior

  col[class*="col-"] {
    // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
    @apply static table-column float-none;
  }

  td,
  th {
    &[class*="col-"] {
      // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      @apply static table-column float-none;
    }
  }
}

caption {
  @apply p-3 text-gray-500 text-left;
}

th {
  @apply text-left;
}


// Baseline styles

.table {
  @apply w-full max-w-full mb-8;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        @apply p-3 align-top border-t border-gray-300;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    @apply align-bottom border-b-2 border-gray-300;
  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        @apply border-t-0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    @apply border-t-2 border-gray-300;
  }

  // Nesting
  .table {
    @apply bg-white;
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  @apply border border-gray-400;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        @apply border border-gray-400;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      @apply border-b-2;
    }
  }
}

// Hover effect
.table-hover {
  > tbody > tr:hover {
    @apply bg-gray-100;
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include tw-table-row-variant('active', theme('colors.gray.200'), theme('colors.gray.300'));
@include tw-table-row-variant('success', theme('colors.green.200'), theme('colors.green.300'));
// I don't think these variants are used anywhere but just in case we need to Tailwindify them:
// @include table-row-variant('info', $state-info-bg);
// @include table-row-variant('warning', $state-warning-bg);
// @include table-row-variant('danger', $state-danger-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  min-height: .01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
  @apply overflow-x-auto;

  @media screen and (max-width: theme('screens.xs-max.max')) {
    -ms-overflow-style: -ms-autohiding-scrollbar;
    @apply w-full mb-4 overflow-y-hidden border border-gray-300;

    // Tighten up spacing
    > .table {
      @apply mb-0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            @apply whitespace-nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      @apply border-0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            @apply border-l-0;
          }
          > th:last-child,
          > td:last-child {
            @apply border-r-0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            @apply border-b-0;
          }
        }
      }

    }
  }
}
