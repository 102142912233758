// Vehicle type selection (a specific variant of .radio-card)

.vehicle-type {
  fill: theme('colors.gray.300');
}

input:checked + .radio-card,
.radio-card.selected {
  .vehicle-type {
    fill: theme('colors.gray.500');
  }
}
