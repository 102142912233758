.cx_floating-menu {
  margin: 0px;
  padding: 0px;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 5px);
  left: -1px;
  box-shadow: g($shadow, 'elevation1');
  max-height: 163px;
  background-color: g($color, 'white');
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  width: 200px;
  min-width: 132px;
}

.cx_floating-menu-filter-vehicle_container {
  top: 18px;
  left: 18px;
  z-index: 100;

  > button {
    height: 36px;
  }
}

.cx_floating-menu-filter-route_style {
  top: 18px;
  left: 160px;
  z-index: 100;

  > .cx_floating-menu {
    width: 130px;
  }
}
