@for $i from 50 through 200 {
  .cx_spinner-#{$i} {
    position: relative;
    background-color: g($color, 'white');
    height: #{$i + 'px'};
    width: #{$i + 'px'};
    border-radius: 50%;
    background: conic-gradient(g($color, 'blue'), transparent);
    animation: bsrotate 1s linear infinite;
  }

  .cx_spinner-#{$i}:before,
  .cx_spinner-#{$i}:after {
    content:"";
    position: absolute;
    border-radius: 50%;
  }

  .cx_spinner-#{$i}:before {
    width: #{calc($i * 60/100) + 'px'};
    height: #{calc($i * 60/100) + 'px'};
    top: #{calc($i * 20/100) + 'px'};
    left: #{calc($i * 20/100) + 'px'};
    background-color: g($color, 'white');
  }

  .cx_spinner-#{$i}:after {
    height: #{calc($i * 20/100) + 'px'};
    width: #{calc($i * 20/100) + 'px'};
    background-color: g($color, 'blue');
    top:0;
    left: #{calc($i * 41/100) + 'px'};
  }
}

@for $i from 15 through 200 {
  .cx_standard-spinner-#{$i} {
    animation: bsrotate 1s linear infinite;
    display: inline-block;
  }
}

@keyframes bsrotate {
  from{ transform: rotateZ(0); }
  to{ transform: rotateZ(360deg); }
}

.cx_paid-spinner__container,
.cx_pdf-spinner__container {
  min-height: 640px;
}

.cx_paid-spinner__title {
  max-width: 200px;
}

.cx_paid-spinner__track {
  position: absolute;
  width: 90px;
  height: 90px;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  background-size: contain;
  background-position: center;
}

.cx_pdf-spinner__title {
  margin-top: 170px;
  margin-bottom: 75px;
}
