.cx_flyout {
  position: relative;
  display: inline-block;
}

.cx_flyout__trigger {
  -webkit-appearance: none;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.cx_i.cx_i--kebab-gray {
  .cx_flyout__trigger & {
    background-size: 24px 24px;
    height: 36px;
    width: 36px;
  }

  .cx_flyout__trigger:hover & {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAgMTJDMTAgMTAuODk1NCAxMC44OTU0IDEwIDEyIDEwQzEzLjEwNDYgMTAgMTQgMTAuODk1NCAxNCAxMkMxNCAxMy4xMDQ2IDEzLjEwNDYgMTQgMTIgMTRDMTAuODk1NCAxNCAxMCAxMy4xMDQ2IDEwIDEyWiIgZmlsbD0iIzAxNGU3ZCIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTEwIDVDMTAgMy44OTU0MyAxMC44OTU0IDMgMTIgM0MxMy4xMDQ2IDMgMTQgMy44OTU0MyAxNCA1QzE0IDYuMTA0NTcgMTMuMTA0NiA3IDEyIDdDMTAuODk1NCA3IDEwIDYuMTA0NTcgMTAgNVoiIGZpbGw9IiMwMTRlN2QiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAxOUMxMCAxNy44OTU0IDEwLjg5NTQgMTcgMTIgMTdDMTMuMTA0NiAxNyAxNCAxNy44OTU0IDE0IDE5QzE0IDIwLjEwNDYgMTMuMTA0NiAyMSAxMiAyMUMxMC44OTU0IDIxIDEwIDIwLjEwNDYgMTAgMTlaIiBmaWxsPSIjMDE0ZTdkIi8+Cjwvc3ZnPgo=');
  }
}

.cx_i.cx_i--kebab-white {
  .cx_flyout__trigger & {
    background-size: 24px 24px;
    height: 36px;
    width: 26px;
  }
}

.cx_flyout__box {
  position: absolute;
  right: 0;
  background-color: g($color, 'white');
  box-shadow: g($shadow, elevation2);
  border-radius: 3px;
  width: 25ch;
  height: auto;
  z-index: 999;

  .cx_flyout--alpha & {
    right: 0;
  }

  .cx_flyout--beta & {
    left: 0;
    right: auto;
  }
}

.cx_flyout__box-down {
  top: calc(100% + 4px);
}

.cx_flyout__box-up {
  bottom: calc(100% + 4px);
}

.cx_button {
  .cx_flyout__box & {
    border-radius: 0;
    justify-content: left;
    width: 100%;

    &:hover {
      background-color: g($color, 'offwhite');
    }

    &:first-child {
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 4px;
    }
  }
}

.cx_button.cx_button--i, .cx_button.cx_button--i-right {
  .cx_flyout__box & {
    padding: 8px 16px 8px 48px;
  }
}

.cx_button.cx_button--i.cx_button--redesign, .cx_button.cx_button--i-right.cx_button--redesign {
  .cx_flyout__box & {
    padding: 8px 16px 8px 36px;
    background-position: 10px;
  }
}

.cx_button_reassign_route.cx_button.cx_button--i {
  .cx_flyout__box & {
    border: none;
    padding: 11px 16px 11px 40px;
  }
}

.cx_button.cx_button--i {
  .cx_flyout__box & {
    background-position: 16px center;
  }
}

.cx_button.cx_button--i-right {
  .cx_flyout__box & {
    background-position: calc(100% - 16px) center;
  }
}

.cx_flyout_delivery_img {
  width: calc(50% - 16px);
  margin-right: 16px;
  margin-bottom: 16px;
  max-height: 210px;
}

.cx_flyout_delivery_img_not_available {
  width: 210px;
  height: 210px;
  margin-right: 16px;
  margin-bottom: 16px;
  background-color: g($color, 'gray');
  display: flex;
  justify-content: center;
  align-items: center;
}
