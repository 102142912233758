.loading-spinner {
  position: relative;
  height: 20px;
  width: 20px;
}

.loading-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top-color: $neutral-700;
  border-right-color: $neutral-200;
  border-bottom-color: $neutral-200;
  border-left-color: $neutral-200;
  animation: spinner 1s linear infinite;
}

.loading-spinner--inverted:before {
  border-top-color: theme('colors.gray.100');
  border-right-color: theme('colors.gray.400');
  border-bottom-color: theme('colors.gray.400');
  border-left-color: theme('colors.gray.400');
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}
